import * as React from "react";
import * as ReactDOM from "react-dom";
import memoize from "lodash/memoize";
import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import keycode from "keycode";
// interfaces
import { AppDispatch } from "app/store";
import { IAppState } from "app/rootReducer";
// helper
import Freeze from "app/common/helpers/freeze";
import * as EnvChecker from "common/helpers/envChecker";
// actions
import { ActionCreators as ImageBrochureActionCreators } from "./actions";
// components
import ResponsiveMenu from "common/components/responsiveMenu";
import {
  MenuWrapper as ResponsiveMenuWrapper,
  MenuItem,
} from "common/components/responsiveMenu/components/menu";
import AppBar from "common/components/appBar";
import MenuWrapper from "common/components/appBar/components/menuWrapper";
import ZNDImage from "./components/zoomAndDragImage";
import { userSelector } from "app/selectors/user";
import { fileSelector } from "app/selectors/file";
import Share from "common/components/share";
import DownloadButton from "common/components/downloadButton";
import {
  BrochureWrapper,
  AppBarStickyWrapperStyle,
  AppBarWrapperStyle,
  Header,
  LeftWrapper,
  TitleInnerWrapper,
  TitleWrapper,
  Title,
  SubTitle,
  ContentWrapper,
  Background,
  CloseButton,
  ShareButton,
  DownloadIconButton,
  MenuDownloadButton,
  MenuShareButton,
  ZoomInButton,
  ZoomOutButton,
  LeftArrowContainer,
  RightArrowContainer,
  ArrowWrapper,
  ArrowIcon,
  PageIndex,
  StyledRawHlsVideo,
} from "./styledComponents";
import IsMobileViewport, {
  IRefHandler,
} from "common/components/isMobileViewport";
import safeParseJSON from "common/helpers/safeParseJSON";
import { MoimURL } from "common/helpers/url";
import { useOpenStateWithHashRoute } from "common/hooks/useOpenState";

const HASH_KEY = "imageBrochure";
const ZOOM_SCOPE_RATE = 0.1;

function mapStateToProps(state: IAppState) {
  const user = userSelector(state, state.imageBrochure.currentAssetOwnerId);
  return {
    isOpen: state.imageBrochure.isOpen,
    initialSrc: state.imageBrochure.initialSrc,
    isPrivate: state.imageBrochure.isPrivate,
    owner: user,
    state,
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return bindActionCreators(
    {
      closeBrochure: ImageBrochureActionCreators.closeImageBrochure,
    },
    dispatch,
  );
}

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps>,
    WrappedComponentProps {}

interface IState {
  currentMedia: HTMLImageElement | HTMLVideoElement | HTMLElement | null;
  mediaType: string | null;
  fileDatum: Moim.Upload.IDenormalizedFile | null;
  currentMediaIndex: number;
  openResponsiveMenu: boolean;
  zoomRate: number;
  displayOwnerInfo: boolean;
}

type IPropsA = Omit<IProps & { onClose(): void }, "closeBrochure">;

class ImageBrochureContainer extends React.PureComponent<IPropsA, IState> {
  public state: IState = {
    currentMedia: null,
    fileDatum: null,
    mediaType: null,
    currentMediaIndex: 0,
    openResponsiveMenu: false,
    zoomRate: 0,
    displayOwnerInfo: false,
  };
  private medias: (HTMLImageElement | HTMLVideoElement)[] = [];
  private readonly freezer: Freeze = new Freeze();
  private readonly refResponsiveMenuWrapper = React.createRef<
    HTMLUListElement
  >();
  private readonly refIsMobile = React.createRef<IRefHandler>();

  public componentDidUpdate(prevProps: IPropsA) {
    if (EnvChecker.isBrowser()) {
      this.preventScrollByOpenState();
      const stateFromCloseToOpen = !prevProps.isOpen && this.props.isOpen;
      const stateFromOpenToClose = prevProps.isOpen && !this.props.isOpen;

      if (stateFromCloseToOpen) {
        const selectedImageElement = document.querySelector<HTMLImageElement>(
          "img[data-brochure-selected=true]",
        );
        const selectedVideoElement = document.querySelector<HTMLVideoElement>(
          "video[data-brochure-selected=true]",
        );
        const selectedBrochureElement = document.querySelector<HTMLElement>(
          "[data-brochure-selected=true]",
        );

        const selectedElement =
          selectedImageElement ||
          selectedVideoElement ||
          selectedBrochureElement;

        if (selectedElement) {
          selectedElement.dataset.brochureSelected = undefined;
          const displayOwnerData =
            MoimURL.ConversationShow.isSame(location.pathname) ||
            MoimURL.DirectMessageShow.isSame(location.pathname);

          this.medias = Array.from(
            document.querySelectorAll<HTMLImageElement | HTMLVideoElement>(
              `img[data-role="${selectedElement.dataset.role}"], video[data-role="${selectedElement.dataset.role}"]`,
            ),
          );

          this.setState({
            mediaType: selectedVideoElement ? "animated-image" : "image",
            currentMedia: selectedElement,
            fileDatum: fileSelector(
              this.props.state,
              selectedElement.dataset.fileId!,
            ),

            currentMediaIndex: this.medias.findIndex(
              imageElement => imageElement === selectedElement,
            ),

            displayOwnerInfo: displayOwnerData,
          });
        }
      } else if (stateFromOpenToClose) {
        this.setState({
          currentMedia: null,
          mediaType: null,
          currentMediaIndex: 0,
          openResponsiveMenu: false,
          displayOwnerInfo: false,
        });
      }
    }
  }

  public render() {
    return this.props.isOpen
      ? ReactDOM.createPortal(
          <BrochureWrapper>
            {this.renderHeader()}
            {this.renderContent()}
            <IsMobileViewport ref={this.refIsMobile} />
          </BrochureWrapper>,
          document.body,
        )
      : null;
  }

  private readonly preventScrollByOpenState = () => {
    if (this.props.isOpen) {
      this.freezer.on();
      window.addEventListener("keydown", this.handleKeyDown);
    } else {
      this.freezer.off();
      window.removeEventListener("keydown", this.handleKeyDown);
    }
  };

  private readonly handleKeyDown: React.EventHandler<any> = e => {
    if (e.keyCode === keycode("esc")) {
      // ESC
      this.handleCloseBrochure();
    } else if (e.keyCode === keycode("left")) {
      // LEFT
      this.movePrevImage();
    } else if (e.keyCode === keycode("right")) {
      // RIGHT
      this.moveNextImage();
    }
  };

  private readonly handleZoomIn = () => {
    this.setState({ zoomRate: this.state.zoomRate + ZOOM_SCOPE_RATE });
  };
  private readonly handleZoomOut = () => {
    const val = this.state.zoomRate - ZOOM_SCOPE_RATE;
    if (val > -0.9) {
      this.setState({ zoomRate: this.state.zoomRate - ZOOM_SCOPE_RATE });
    }
  };

  private readonly renderHeader = () => {
    const { initialSrc, isPrivate } = this.props;
    const { openResponsiveMenu, fileDatum, currentMedia } = this.state;
    const owner = fileDatum?.user ?? this.props.owner;

    const crrSrc =
      currentMedia instanceof HTMLImageElement
        ? currentMedia.src
        : initialSrc ?? "";

    const createdAt = fileDatum?.created_at
      ? new Date(fileDatum.created_at)
      : null;
    const isMobile = this.isMobile();
    const fileUrl =
      currentMedia?.dataset.fileUrl ?? fileDatum?.url_private ?? crrSrc;
    const canDownload = fileUrl && new URL(fileUrl).pathname !== "/";
    const fileName =
      crrSrc.split("/").pop() ??
      fileDatum?.title ??
      initialSrc.split("/").pop() ??
      "";
    const hideActionButton =
      this.state.currentMedia?.dataset.hideActionButton === "true";
    return (
      <Header>
        <AppBar
          wrapperStyle={AppBarWrapperStyle}
          wrapperStickyStyle={AppBarStickyWrapperStyle}
          leftButton={
            isMobile && (
              <LeftWrapper>
                <CloseButton onClick={this.handleCloseBrochure} />
              </LeftWrapper>
            )
          }
          titleElement={
            this.state.displayOwnerInfo &&
            owner &&
            owner.id && (
              <TitleWrapper>
                <TitleInnerWrapper>
                  <Title>{owner.name}</Title>
                  <SubTitle>
                    {createdAt && (
                      <>
                        <FormattedDate value={createdAt} />{" "}
                        <FormattedTime value={createdAt} />
                      </>
                    )}
                  </SubTitle>
                </TitleInnerWrapper>
              </TitleWrapper>
            )
          }
          rightButton={
            !hideActionButton ? (
              <MenuWrapper
                onClickMoreMenu={this.openResponsiveMenu}
                enableSmartShorten={true}
                moreIconColor="white"
              >
                <ZoomInButton role="button" onClick={this.handleZoomIn} />
                <ZoomOutButton role="button" onClick={this.handleZoomOut} />

                <DownloadButton
                  isPrivate={isPrivate}
                  key="download_button"
                  resourceUrl={fileUrl}
                  fileName={fileName}
                  disable={!canDownload}
                >
                  <DownloadIconButton disable={!canDownload} />
                </DownloadButton>
                <Share
                  displayText={<ShareButton key="share_button" />}
                  copyValue={fileUrl}
                />

                {!isMobile && (
                  <CloseButton onClick={this.handleCloseBrochure} />
                )}
              </MenuWrapper>
            ) : (
              !isMobile && <CloseButton onClick={this.handleCloseBrochure} />
            )
          }
        />
        <ResponsiveMenu
          open={openResponsiveMenu}
          minHeight={this.getContentHeight(
            this.refResponsiveMenuWrapper.current,
          )}
          onCloseRequest={this.closeResponsiveMenu}
        >
          <ResponsiveMenuWrapper ref={this.refResponsiveMenuWrapper}>
            <MenuItem key="download_button">
              <MenuDownloadButton
                key="download_button"
                resourceUrl={fileUrl}
                fileName={fileName}
                isPrivate={isPrivate}
              >
                <FormattedMessage id="media_detail/more_menu_save" />
              </MenuDownloadButton>
            </MenuItem>
            <MenuItem key="share_button">
              <Share
                displayText={
                  <FormattedMessage id="media_detail/more_menu_share" />
                }
                displayTextWrapper={MenuShareButton}
                copyValue={fileUrl}
              />
            </MenuItem>
          </ResponsiveMenuWrapper>
        </ResponsiveMenu>
      </Header>
    );
  };

  private readonly openResponsiveMenu = () => {
    this.setState({ openResponsiveMenu: true });
  };
  private readonly closeResponsiveMenu = () => {
    this.setState({ openResponsiveMenu: false });
  };

  private readonly renderContent = () => {
    const { mediaType } = this.state;

    return (
      <ContentWrapper>
        <Background onClick={this.handleCloseBrochure} />
        {mediaType === "image"
          ? this.renderImage()
          : this.renderAnimatedImage()}
        {!this.isFirstImage() && (
          <LeftArrowContainer>
            <ArrowWrapper>
              <ArrowIcon onClick={this.movePrevImage} />
            </ArrowWrapper>
          </LeftArrowContainer>
        )}
        {!this.isLastImage() && (
          <RightArrowContainer>
            <ArrowWrapper>
              <ArrowIcon onClick={this.moveNextImage} />
            </ArrowWrapper>
          </RightArrowContainer>
        )}
        {this.medias.length > 1 && (
          <PageIndex>
            <span>
              {this.state.currentMediaIndex + 1}/{this.medias.length}
            </span>
          </PageIndex>
        )}
      </ContentWrapper>
    );
  };

  private readonly handleZoomChange = (inZoom: boolean) => {
    if (!inZoom) {
      this.setState({
        zoomRate: 0,
      });
    }
  };

  private readonly renderImage = () => {
    const targetMedia = this.state.currentMedia;
    const { initialSrc } = this.props;

    if (!targetMedia) {
      return;
    }
    const imageSrc =
      targetMedia instanceof HTMLImageElement
        ? targetMedia.src
        : initialSrc ?? "";

    const srcSet =
      targetMedia instanceof HTMLImageElement ? targetMedia.srcset : initialSrc;

    return (
      <ZNDImage
        src={imageSrc}
        alt={`brochure image ${imageSrc.split("/").pop()}`}
        srcSet={srcSet}
        adjustZoomOffset={this.state.zoomRate}
        onZoomChange={this.handleZoomChange}
        onClickDragWrapper={this.handleCloseBrochure}
        style={{ width: "100vw", height: "80vh", objectFit: "contain" }}
      />
    );
  };

  private readonly renderAnimatedImage = () => {
    const targetMedia: HTMLVideoElement = this.state
      .currentMedia as HTMLVideoElement;

    if (!targetMedia) {
      return;
    }

    const maxHeight = innerHeight * 0.8;
    let width = innerWidth;
    let height = Math.floor(width * (9 / 16));
    if (height > maxHeight) {
      height = maxHeight;
    }

    return (
      <StyledRawHlsVideo
        key={this.state.currentMediaIndex}
        style={{ width: "100%" }}
        controls={true}
        sources={safeParseJSON(targetMedia.dataset.sources ?? "", [])}
        poster={targetMedia.poster}
        width={Math.floor(width)}
        height={Math.floor(height)}
      />
    );
  };

  private readonly isMobile = () => Boolean(this.refIsMobile.current?.isMobile);

  private readonly handleCloseBrochure = () => {
    this.setState({ zoomRate: 0 });
    this.props.onClose();
  };

  private readonly isLastImage = () =>
    this.state.currentMediaIndex ===
    (this.medias.length !== 0 ? this.medias.length - 1 : 0);

  private readonly isFirstImage = () => this.state.currentMediaIndex === 0;

  private readonly movePrevImage = () => {
    if (this.isFirstImage()) {
      return;
    }

    const prevIndex = this.state.currentMediaIndex - 1;
    const nextMedia = this.medias[prevIndex];
    this.setState({
      currentMedia: nextMedia,
      currentMediaIndex: prevIndex,
      zoomRate: 0,
      mediaType:
        nextMedia instanceof HTMLVideoElement ? "animated-image" : "image",
      fileDatum: fileSelector(this.props.state, nextMedia.dataset.fileId!),
    });
  };

  private readonly moveNextImage = () => {
    if (this.isLastImage()) {
      return;
    }
    const nextIndex = this.state.currentMediaIndex + 1;
    const nextMedia = this.medias[nextIndex];
    this.setState({
      currentMedia: nextMedia,
      currentMediaIndex: nextIndex,
      zoomRate: 0,
      mediaType:
        nextMedia instanceof HTMLVideoElement ? "animated-image" : "image",
      fileDatum: fileSelector(this.props.state, nextMedia.dataset.fileId!),
    });
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private readonly getContentHeight = memoize((elem: Element | null) =>
    elem ? elem.clientHeight : undefined,
  );
}

const WithHashRoute = ({ closeBrochure, ...props }: IProps) => {
  const { open, close } = useOpenStateWithHashRoute(HASH_KEY, {
    onHashDismiss: closeBrochure,
  });

  const handleClose = React.useCallback(() => {
    closeBrochure();
    close();
  }, [closeBrochure]);

  React.useEffect(() => {
    if (props.isOpen) {
      open();
    }
  }, [props.isOpen]);

  return <ImageBrochureContainer {...props} onClose={handleClose} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(WithHashRoute));
