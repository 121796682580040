import * as React from "react";

import {
  Wrapper,
  Header,
  Contents,
  Description,
  Title,
  SubTitle,
  ButtonWrapper,
  ButtonDescription,
  Button,
} from "./styled";
import ExternalMoimLink from "common/components/externalMoimLink";
import { GhostGeneralButton } from "common/components/designSystem/buttons";

interface IProps extends React.PropsWithChildren<{}> {
  stepData: Moim.Group.ICreateMoimStepData;
  disabledButton?: boolean;
  waitingButton?: boolean;
  link?: string;
  onClick?: () => void;
  onClickSkipButton?: () => void;
}

export default function BaseTemplate({
  stepData,
  disabledButton,
  waitingButton,
  link,
  onClick,
  onClickSkipButton,
  children,
}: IProps) {
  const buttonElement = (
    <>
      {stepData.buttonDescription && (
        <ButtonDescription>{stepData.buttonDescription}</ButtonDescription>
      )}
      <ButtonWrapper>
        {stepData.skipButtonText ? (
          <GhostGeneralButton size="l" onClick={onClickSkipButton}>
            {stepData.skipButtonText}
          </GhostGeneralButton>
        ) : null}
        <Button
          disabled={disabledButton}
          waiting={waitingButton}
          size="l"
          onClick={onClick}
        >
          {stepData.buttonText}
        </Button>
      </ButtonWrapper>
    </>
  );

  return (
    <Wrapper>
      <Header>
        <Title>{stepData.title}</Title>
        {stepData.subTitle && <SubTitle>{stepData.subTitle}</SubTitle>}
      </Header>
      <Contents>{children}</Contents>
      {link ? (
        <ExternalMoimLink domain={link}>{buttonElement}</ExternalMoimLink>
      ) : (
        buttonElement
      )}
    </Wrapper>
  );
}

export { Description };
