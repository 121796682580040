import * as React from "react";
import { ActionCreators as GroupActionCreators } from "app/actions/group";
import GlobalUserStatusFeedbackDialog from "common/components/dialogs/globalUserStatusFeedbackDialog";
import JoinGroupDialog from "common/components/dialogs/joinGroupDialog";
import useCurrentUser from "common/hooks/useCurrentUser";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { UserStatusTypes } from "app/enums";
import { useOpenGlobalUserStatusFeedbackDialog } from "common/components/dialogs/globalUserStatusFeedbackDialog/hooks";
import { getSignUpConfig2Step } from "common/components/dialogs/joinGroupDialog/container/current";
import { useActions, useStoreState } from "app/store";
import useCurrentHubGroup from "common/hooks/useCurrentHubGroup";

/**
 * @migration need migration.
 * NOTE: https://github.com/balmbees/moim-web/issues/3049
 */
const JoinGroupDialogOpener: React.FC = () => {
  const currentUser = useCurrentUser();
  const currentGroup = useCurrentGroup();
  const hubGroup = useCurrentHubGroup();

  const { openJoinGroupDialog } = useActions({
    openJoinGroupDialog: GroupActionCreators.openJoinGroupDialog,
  });
  const openUserStatusFeedbackDialog = useOpenGlobalUserStatusFeedbackDialog();

  const { parentUser, dialogForceClose } = useStoreState(storeState => ({
    parentUser: storeState.app.parentMoimUser,
    dialogForceClose: storeState.joinGroupDialog.forceClose,
  }));
  const [actioned, setActioned] = React.useState(false);

  const parentSigned = Boolean(!currentGroup?.is_hub && parentUser);
  const isChildGroup = Boolean(currentGroup?.parent);

  const joinDialogType: Moim.Group.JoinGroupDialogType = React.useMemo(() => {
    if (isChildGroup) {
      if ((currentGroup?.is_hub && !parentUser) || parentSigned) {
        if (parentUser?.status === UserStatusTypes.ACTIVE) {
          return "current";
        }
        return "parent";
      }
      return "current";
    }
    return "current";
  }, [isChildGroup, currentGroup, parentUser, parentSigned]);

  const childUserSignFlow = React.useCallback(() => {
    if (currentUser) {
      switch (currentUser.status) {
        case UserStatusTypes.PENDING: {
          const steps = getSignUpConfig2Step(currentGroup?.sign_up_config_v2);
          const initStep = steps[
            steps.findIndex(
              step => step?.key === currentUser.signUpInfo.finishedStep,
            ) + 1
          ]?.key as Moim.Group.JoinGroupDialogStepType;

          if (initStep) {
            setActioned(true);
            openJoinGroupDialog(joinDialogType, initStep);
          }
          break;
        }
        case UserStatusTypes.WAITING_FOR_APPROVAL:
        case UserStatusTypes.REJECTED:
          openUserStatusFeedbackDialog();
          break;
      }
    } else {
      const alreadySignTried = document.referrer
        ? new URL(document.referrer).pathname ===
          "/oauth/connect/cryptobadge/callback"
        : false;
      if (alreadySignTried) {
        setActioned(true);
        openJoinGroupDialog("current", "main");
      }
    }
  }, [
    currentGroup?.sign_up_config_v2,
    currentUser,
    joinDialogType,
    openJoinGroupDialog,
    openUserStatusFeedbackDialog,
  ]);

  React.useEffect(() => {
    if (!actioned && !dialogForceClose) {
      if (isChildGroup) {
        switch (parentUser?.status) {
          case UserStatusTypes.ACTIVE: {
            childUserSignFlow();
            break;
          }
          case UserStatusTypes.PENDING: {
            const steps = getSignUpConfig2Step(hubGroup?.sign_up_config_v2);
            const initStep = steps[
              steps.findIndex(
                step => step?.key === parentUser.signUpInfo.finishedStep,
              ) + 1
            ]?.key as Moim.Group.JoinGroupDialogStepType;

            if (initStep) {
              setActioned(true);
              openJoinGroupDialog(joinDialogType, initStep);
            }
            break;
          }
          case UserStatusTypes.WAITING_FOR_APPROVAL:
          case UserStatusTypes.REJECTED:
            openUserStatusFeedbackDialog();
            break;
        }
      } else {
        childUserSignFlow();
      }
    }
  }, [
    dialogForceClose,
    hubGroup,
    parentUser,
    joinDialogType,
    childUserSignFlow,
    actioned,
  ]);

  return (
    <>
      <GlobalUserStatusFeedbackDialog />
      <JoinGroupDialog />
    </>
  );
};

export default React.memo(JoinGroupDialogOpener);
