import * as React from "react";
import loadable, { OptionsWithoutResolver } from "@loadable/component";
import LoadingIcon from "common/components/loading/icon";

export function VingleLoadable(
  component: () => Promise<any>,
  options?: OptionsWithoutResolver<any>,
) {
  return loadable<any>(component, {
    fallback: <LoadingIcon />,
    ...options,
  });
}

export class VingleError extends Error {}
