import * as React from "react";
import { FormattedNumber } from "react-intl";
import { SmallBadge } from "common/components/cryptobadge";
import { BadgeWrapper as SmallBadgeWrapper } from "common/components/cryptobadge/profileCertificateItem/small/styled";
import { Wrapper, MoreCount } from "./styled";
import { BadgeSmallSkeleton } from "common/components/cryptobadge/profileCertificateItem/normal/skeleton";
import { userCertificationSelector } from "app/selectors/cryptobadge";
import { useActions, useStoreState } from "app/store";
import { getCertificatesByUserId } from "app/actions/cryptobadge";

const MAX_VISIBLE_BADGE = 6;

interface IProps {
  user: Moim.User.IUser;
  issuers?: Moim.Id[];
  onClickMore(): void;
}

const CryptoBadges: React.FC<IProps> = ({ user, issuers, onClickMore }) => {
  const { isLoading, cryptoBadges } = useStoreState(state => ({
    isLoading: Boolean(state.profilePage.isCryptoBadgeLoading[user?.id]),
    cryptoBadges: userCertificationSelector(
      state,
      user?.certifications || { data: [] },
      issuers,
    ),
  }));
  const { dispatchGetCertifications } = useActions({
    dispatchGetCertifications: getCertificatesByUserId,
  });

  const badges = React.useMemo(() => {
    if (isLoading) {
      return new Array(4)
        .fill(0)
        .map((_, idx) => <BadgeSmallSkeleton key={`badge_skeleton_${idx}`} />);
    }

    return cryptoBadges.data
      .filter(item => Boolean(item.node))
      .slice(0, MAX_VISIBLE_BADGE)
      .map(certification => (
        <SmallBadge
          key={certification.node?.id}
          certificateId={certification.node?.id || ""}
          icon={certification.node?.imageUri || ""}
          name={certification.node?.name || ""}
        />
      ));
  }, [isLoading, cryptoBadges]);

  const moreCount = React.useMemo(() => {
    if (cryptoBadges.data.length > MAX_VISIBLE_BADGE) {
      const remainCount = cryptoBadges.data.length - MAX_VISIBLE_BADGE;
      return (
        <SmallBadgeWrapper role="button" onClick={onClickMore}>
          <MoreCount>
            +<FormattedNumber value={remainCount} notation="compact" />
          </MoreCount>
        </SmallBadgeWrapper>
      );
    }
    return null;
  }, [cryptoBadges.data.length, onClickMore]);

  React.useEffect(() => {
    if (user.canId && !isLoading) {
      dispatchGetCertifications(user.id, { canId: user.canId, issuers });
    }
  }, [user?.canId]);

  if (!isLoading && !badges.length) {
    return null;
  }

  return (
    <Wrapper>
      {badges}
      {moreCount}
    </Wrapper>
  );
};

export default CryptoBadges;
