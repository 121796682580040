import { Svg24MoreW, Svg24MoreB, Svg24MoreG } from "@moim/icons";
import * as React from "react";
import styled from "styled-components";
// icons



// helpers
import useIsMobile from "common/hooks/useIsMobile";
import { px2rem } from "common/helpers/rem";

interface IProps {
  onClickMoreMenu: VoidFunction;
  enableSmartShorten?: boolean;
  moreIconColor?: "white" | "black" | "grey";
}

const IconSelector = (color: "white" | "black" | "grey") => {
  switch (color) {
    case "white":
      return Svg24MoreW;
    case "black":
      return Svg24MoreB;
    case "grey":
      return Svg24MoreG;
  }
};

const MenuWrapper: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  onClickMoreMenu,
  enableSmartShorten,
  moreIconColor = "black",
}) => {
  const isMobile = useIsMobile();
  const MoreIcon = IconSelector(moreIconColor);

  if (enableSmartShorten && isMobile) {
    return (
      <MoreButton onClick={onClickMoreMenu}>
        <MoreIcon size="s" touch={44} />
      </MoreButton>
    );
  } else {
    return <>{children}</>;
  }
};

const MoreButton = styled.button`
  width: ${px2rem(44)};
  height: ${px2rem(44)};
`;

export default MenuWrapper;
