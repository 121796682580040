import * as React from "react";

import Texts from "./blocks/texts";
import GridBlock from "./blocks/grid";
import ImageBlock from "./blocks/image";
import ProductListPreview from "./blocks/commerce/productListPreview";
import ContentGroupPreview from "./blocks/contentsGroupPreview";
import Spacer from "./blocks/spacer";
import SectionBlock from "./blocks/section";
import BlockitRenderer from "../blockitRenderer";
import Carousel from "./blocks/carousel";
import QuickLInkNavigation from "./blocks/quickLInkNavigation";
import Button from "./blocks/button";
import BrandListPreview from "./blocks/brandListPreview";
import CommerceCategory from "./blocks/commerce/category";
import DquestGroupPreview from "./blocks/dquestGroupPreview";
import ProductList from "./blocks/commerce/productList";
import ChildMoimGroup from "./blocks/childMoimGroup";
import ChildMoimGroupPreview from "./blocks/childMoimGroup/preview";
import VideoBlock from "./blocks/video";
import NFTSummaryBlock from "./blocks/web3/nftSummary";
import NFTGroupPreview from "./blocks/web3/nftGroupPreview";
import NFTCollectionPreviewBlock from "./blocks/web3/nftCollectionItem";
import CryptobadgeGroupPreview from "./blocks/web3/cryptobadgeGroupPreview";

interface IProps {
  block: Moim.Blockit.V2.BlockType;
  className?: string;
}

const BlockitRendererV2: React.FC<IProps> = ({ block, className }) => {
  if (!block.version?.startsWith("2")) {
    return <BlockitRenderer block={block as Moim.Blockit.Blocks} />;
  }

  switch (block.type) {
    case "carousel": {
      return <Carousel className={className} {...block} />;
    }
    case "text": {
      return <Texts className={className} {...block} />;
    }
    case "image": {
      return <ImageBlock className={className} {...block} />;
    }
    case "grid": {
      return <GridBlock className={className} {...block} />;
    }
    case "product-list-preview": {
      return <ProductListPreview className={className} {...block} />;
    }
    case "product-list": {
      return <ProductList className={className} {...block} />;
    }
    case "content-group-preview": {
      return <ContentGroupPreview className={className} {...block} />;
    }
    case "spacer": {
      return <Spacer className={className} {...block} />;
    }
    case "section": {
      return <SectionBlock className={className} {...block} />;
    }
    case "quick-link-navigation": {
      return <QuickLInkNavigation className={className} {...block} />;
    }
    case "brand-list-preview": {
      return <BrandListPreview className={className} {...block} />;
    }
    case "button": {
      return <Button className={className} {...block} />;
    }

    case "dquest-group-preview": {
      return <DquestGroupPreview {...block} />;
    }
    case "category": {
      return <CommerceCategory {...block} />;
    }
    case "child-moim-group":
      return <ChildMoimGroup {...block} />;
    case "child-moim-group-preview":
      return <ChildMoimGroupPreview {...block} />;

    case "video":
      return <VideoBlock {...block} />;

    case "nft-summary":
      return <NFTSummaryBlock {...block} />;
    case "nft-group-preview":
      return <NFTGroupPreview {...block} />;
    case "nft-collection-preview":
      return <NFTCollectionPreviewBlock {...block} />;
    case "cryptobadge-group-preview":
      return <CryptobadgeGroupPreview {...block} />;
    default: {
      return null;
    }
  }
};

export default BlockitRendererV2;
