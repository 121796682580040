import * as React from "react";

import ParentJoinDialog from "./container/parent";
import CurrentJoinDialog, { IRef } from "./container/current";
import { CompactLayout } from "./component/layout/compact";

import { useStoreState } from "app/store";
import {
  getCanRefreshTokenFromMoimToken,
  getCanTokenFromMoimToken,
  getMoimTokenToCookie,
} from "common/helpers/authentication";
import { getOAuthTokenForGroup } from "common/helpers/cryptoBadgeHandlerWithInMemory";

import selectHubMoimId from "common/helpers/selectHubMoimId";
import { MoimURL } from "common/helpers/url";
import useRedirect from "common/hooks/useRedirect";

const JoinGroupDialog: React.FC<{}> = () => {
  const redirect = useRedirect();
  const { open, type, hubGroupId, options } = useStoreState(state => ({
    type: state.joinGroupDialog.type,
    open: state.joinGroupDialog.open,
    hubGroupId: selectHubMoimId(state),
    options: state.joinGroupDialog.options,
  }));

  const ref = React.useRef<IRef>(null);

  const authentication = React.useMemo(() => {
    if (options?.token) {
      return {
        provider: options.provider ?? "cryptobadge",
        token: options.token,
        group: hubGroupId,
        refreshToken: options.refreshToken,
      } as Moim.IAuthentication;
    }

    const token = hubGroupId
      ? getMoimTokenToCookie(hubGroupId)?.access_token
      : undefined;
    const canToken = getCanTokenFromMoimToken(token);
    const refreshToken = getCanRefreshTokenFromMoimToken(token);

    const newAuthentication: Moim.IAuthentication | null =
      hubGroupId && canToken
        ? {
            provider: options?.provider ?? "cryptobadge",
            token: canToken,
            group: hubGroupId,
            refreshToken,
          }
        : getOAuthTokenForGroup();

    return newAuthentication;
  }, [options, hubGroupId]);

  const handleClose = React.useCallback(() => {
    const isEditorPath = MoimURL.BlockitEditor.isSame(location.pathname);
    if (isEditorPath) {
      redirect(new MoimURL.MoimAppHome().toString());
    }
    ref.current?.onClose();
  }, []);

  return (
    <CompactLayout open={open} onClose={handleClose}>
      {type === "current" ? (
        <CurrentJoinDialog ref={ref} authentication={authentication} />
      ) : (
        <ParentJoinDialog ref={ref} authentication={authentication} />
      )}
    </CompactLayout>
  );
};

export default JoinGroupDialog;
