import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import { selectPreviewProfileBlocks } from "app/selectors/profile";
import { useStoreState } from "app/store";
import BlockitRenderer from "common/components/blockitEditorBase/components/blockitRenderer";
import Positions from "../../positions";
import CryptoBadges from "../../cryptobadges";
import UserNfts from "../../userNfts";
import UserPostCountStat from "../../userPostCountStat";
import { Body, BlockitWrapper } from "../styledComponent";

interface IProps {
  userData: Moim.User.IUser;
  onProfileClick(): void;
  onChangeBodyResize(): void;
  onCloseRequest(): void;
}

const ProfileDialogBody = ({
  userData,
  onProfileClick,
  onChangeBodyResize,
}: IProps) => {
  const { positions } = userData;
  const blocks = useStoreState(state => selectPreviewProfileBlocks(state));
  const { ref } = useResizeDetector({
    handleHeight: true,
    onResize: onChangeBodyResize,
  });

  if (Boolean(blocks?.length)) {
    return (
      <div ref={ref}>
        <Body>
          {blocks?.map((block: any) => {
            let content: React.ReactNode = null;
            switch (block.type) {
              case "nft-list-preview":
                content = (
                  <UserNfts
                    userId={userData.id}
                    accountId={userData.metamask}
                  />
                );
                break;

              case "positions":
                content = positions?.length ? (
                  <Positions
                    positions={positions}
                    onClickMore={onProfileClick}
                  />
                ) : null;
                break;
              case "badges":
                content = (
                  <CryptoBadges
                    issuers={block.issuers}
                    user={userData}
                    onClickMore={onProfileClick}
                  />
                );
                break;
              case "user-contents-list": {
                content = userData?.id ? (
                  <UserPostCountStat
                    userId={userData.id}
                    types={block.children}
                    title={block.title}
                    pageTitle={block.pageTitle}
                  />
                ) : null;
                break;
              }
              default:
                content = <BlockitRenderer block={block} />;
                break;
            }

            if (content) {
              return (
                <BlockitWrapper key={`profile_preview_block_${block.type}`}>
                  {content}
                </BlockitWrapper>
              );
            }
            return null;
          })}
        </Body>
      </div>
    );
  }

  return null;
};

export default ProfileDialogBody;
