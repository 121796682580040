import { Svg24CloseB } from "@moim/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import BasicResponsiveDialog from "common/components/basicResponsiveDialog";
import styled, { css } from "styled-components";


import { px2rem } from "common/helpers/rem";
import { MEDIA_QUERY } from "common/constants/responsive";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const AppBarWrapper = styled.div`
  padding: ${px2rem(8)} ${px2rem(4)} ${px2rem(0)};
`;

export const CloseButtonWrapper = styled.div`
  margin-left: ${px2rem(13)};
  display: flex;
  align-items: center;
`;

export const CloseButton = styled(Svg24CloseB).attrs({
  size: "s",
  touch: 24,
  role: "button",
})``;

export const Dialog = styled(
  withStyles({
    root: {
      zIndex: "1301 !important" as any,
    },
  })(BasicResponsiveDialog),
)<{ height?: number }>`
  .MuiPaper-root {
    @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
      ${props => {
        return (
          props.height &&
          css`
            height: ${px2rem(props.height)};
          `
        );
      }}
    }
  }
`;
