import { CancelToken } from "axios";
import { MoimBaseAPI } from "common/api/base";

export default class ComponentLayoutAPI extends MoimBaseAPI {
  public async getComponentLayout(
    id: Moim.Id,
    type: string,
    platform?: string,
    cancelToken?: CancelToken,
  ): Promise<Moim.ISingleItemResponse<Moim.Component.IComponentLayout>> {
    return (
      await this.get(
        `/component_layouts/${id}`,
        { type, platform },
        { cancelToken },
      )
    ).data;
  }
}
