import * as React from "react";
// components
import JoinDialogComponent from "./component";
import DialogStepController from "common/components/dialogs/dialogStepController";

import { useActions, useStoreState } from "app/store";
import useCurrentHubGroup from "common/hooks/useCurrentHubGroup";

import {
  ActionCreators as GroupActionCreators,
  getParentGroup,
} from "app/actions/group";
import { currentGroupSelector } from "app/selectors/app";
import { getSignUpConfig2Step } from "./current";

interface IProps {
  authentication: Moim.IAuthentication | null;
}

interface IRef {
  onClose(): void;
}
const ParentJoinDialog = React.forwardRef<IRef, IProps>(
  ({ authentication }, ref) => {
    const parentGroup = useCurrentHubGroup();

    const { open, initialStep, currentGroup } = useStoreState(state => ({
      open: state.joinGroupDialog.open,
      initialStep: state.joinGroupDialog.initialStep,
      currentGroup: currentGroupSelector(state),
    }));
    const { dispatchGetParentGroup, dispatchOpenDialog } = useActions({
      dispatchOpenDialog: GroupActionCreators.openJoinGroupDialog,
      dispatchGetParentGroup: getParentGroup,
    });

    React.useEffect(() => {
      if (!parentGroup && currentGroup) {
        dispatchGetParentGroup(currentGroup.parent ?? "");
      }
    }, [open]);
    const steps = React.useMemo(
      () => [
        {
          key: "username",
          previewButtonText: "",
          nextButtonText: "",
          default: initialStep === "username",
        },
        ...getSignUpConfig2Step(parentGroup?.sign_up_config_v2, initialStep),
      ],
      [parentGroup, initialStep],
    );

    return (
      <DialogStepController steps={steps}>
        <JoinDialogComponent
          ref={ref}
          authentication={authentication}
          group={parentGroup}
          onClickDoneButton={() => {
            dispatchOpenDialog("current");
          }}
        />
      </DialogStepController>
    );
  },
);

export default ParentJoinDialog;
