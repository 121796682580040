import { useActions } from "app/store";
import { ActionCreators } from "./actions";
import { signOut } from "app/actions/app";
import { useCallback } from "react";

export function useOpenGlobalUserStatusFeedbackDialog() {
  const { open } = useActions({ open: ActionCreators.open });
  return open;
}

export function useCloseGlobalUserStatusFeedbackDialog() {
  const { close, dispatchSignOut } = useActions({
    close: ActionCreators.close,
    dispatchSignOut: signOut,
  });

  return useCallback(() => {
    dispatchSignOut();
    close();
  }, []);
}
