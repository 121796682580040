import { useStoreState } from "app/store";
import { useCurrentHubSellerId } from "common/hooks/commerce/useHubSeller";
import React from "react";
import UnsignedChecker from ".";

type IProps = React.PropsWithChildren<{
  fallbackType?: Moim.Enums.PermissionDeniedFallbackType;
  unsignedCustomElement?: React.ReactNode;
  className?: string;
  groupId?: string;
}>;

const HidePriceForGuestChecker = ({
  children,
  unsignedCustomElement,
  ...props
}: IProps) => {
  const hubSellerId = useCurrentHubSellerId();

  const { hidePriceForGuest } = useStoreState(state => ({
    hidePriceForGuest:
      state.entities.commerce_seller[hubSellerId ?? ""]?.config
        ?.hidePriceForGuest,
  }));

  if (!hidePriceForGuest) return <>{children}</>;

  return (
    <UnsignedChecker
      {...props}
      fallbackType={unsignedCustomElement ? "CUSTOM" : "NONE"}
      unsignedCustomElement={unsignedCustomElement}
    >
      {children}
    </UnsignedChecker>
  );
};

export default HidePriceForGuestChecker;
