import * as React from "react";
import { useStoreState } from "app/store";
import { getStoreCommerceCategoriesSelector } from "app/selectors/commerce";
import { MoimURL } from "common/helpers/url";
import Skeleton from "./skeleton";
import { Wrapper } from "./styled";
import { withPlacement } from "../../../hoc/withPlacement";

import { AnalyticsClass } from "common/helpers/analytics/analytics";
import BlockitListLayout from "common/components/blockitListLayout";
import {
  DimmedLeftArrow,
  DimmedRightArrow,
} from "common/components/horizontalScroll/arrows";
import { useParseListElementConfig } from "common/components/blockitListLayout/hooks/useParseListElementConfig";
import QuickLinkItem from "../../quickLInkNavigation/components/item";

/**
 * Note: category id all 케이스는 bootAPI호출 직후 getCategories()호출
 * 별도로 categoryId가 주어졌을때 케이스는 추후 개발.
 */
const CommerceCategoryBlock: React.FC<Moim.Blockit.V2.Commerce.ICategoryBlock> = ({
  listElement,
  itemStyle,
}) => {
  const { categories, isLoading } = useStoreState(state => ({
    categories: getStoreCommerceCategoriesSelector(state),
    isLoading: state.commerce.getCategoriesLoading,
  }));

  const { maxVisibleCount } = useParseListElementConfig(listElement);
  const elements = React.useMemo(() => {
    if (isLoading && categories.data.length === 0) {
      return new Array(maxVisibleCount).fill(0).map(_ => <Skeleton />);
    }

    return categories.data.map(i => (
      <QuickLinkItem
        key={`category_block_item_${i.id}`}
        title={i.name}
        src={i.imageUrl}
        href={new MoimURL.CommerceCategories({
          id: i.id,
          section: "products",
        }).toString()}
        onSelect={() => {
          AnalyticsClass.getInstance().blockCategoryNavigationSelect({
            categoryId: i.id,
          });
        }}
        itemStyle={itemStyle}
      />
    ));
  }, [isLoading, categories.data, maxVisibleCount, itemStyle]);

  return (
    <Wrapper>
      <BlockitListLayout
        element={listElement}
        leftArrow={DimmedLeftArrow}
        rightArrow={DimmedRightArrow}
      >
        {elements}
      </BlockitListLayout>
    </Wrapper>
  );
};

export default withPlacement(React.memo(CommerceCategoryBlock));
