import { Svg18RightarrowG, Svg18ForumPostB } from "@moim/icons";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B4Regular } from "common/components/designSystem/typos";



export const Wrapper = styled.div`
  width: 100%;
  padding: 0 ${px2rem(4)} 0 ${px2rem(16)};
  display: flex;
  align-items: center;
  color: ${props => props.theme.colorV2.colorSet.grey600};
`;

export const Title = styled(B4Regular)`
  padding: ${px2rem(8)} 0;
  margin-right: ${px2rem(3)};
  flex: 1;
  min-width: 0;
`;

export const Count = styled.span`
  margin-left: ${px2rem(4)};
`;

export const RightArrow = styled(Svg18RightarrowG).attrs(props => ({
  size: "xs",
  touch: 42,
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const PostIconWrapper = styled.div`
  width: ${px2rem(18)};
  height: ${px2rem(18)};
  margin-right: ${px2rem(6)};
`;

export const PostIcon = styled(Svg18ForumPostB).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;
