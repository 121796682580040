import { Svg24NotiOnB, Svg24NotiOffB, Svg24NotiNothingB, Svg24NotiB } from "@moim/icons";
import styled from "styled-components";
// icons





export const NotiOnIcon = styled(Svg24NotiOnB).attrs(props => ({
  size: "s",
  touch: props.size ?? 42,
  iconColor: props.theme.colorV2.colorSet.grey800,
}))``;
export const NotiOffIcon = styled(Svg24NotiOffB).attrs(props => ({
  size: "s",
  touch: props.size ?? 42,
  iconColor: props.theme.colorV2.colorSet.grey800,
}))``;
export const NotiNothingIcon = styled(Svg24NotiNothingB).attrs(props => ({
  size: "s",
  touch: props.size ?? 42,
  iconColor: props.theme.colorV2.colorSet.grey800,
}))``;
export const NotiDefaultIcon = styled(Svg24NotiB).attrs(props => ({
  size: "s",
  touch: props.size ?? 42,
  iconColor: props.theme.colorV2.colorSet.grey800,
}))``;
