import styled from "styled-components";
import {
  BlockedUserPlaceholderL,
  BlockedUserPlaceholderM,
  BlockedUserPlaceholderS,
  BlockedUserPlaceholderXl,
  BlockedUserPlaceholderXs,
} from "@moim/icons";

import { ImageStyle } from "./styledComponents";

interface IBlockedUserIconProps {
  elementPaletteProps?: Moim.Theme.CommonElementThemePaletteProps;
}

interface IBlockedUserPlaceholder extends IBlockedUserIconProps {
  size: Moim.DesignSystem.Size;
}
export const BlockedUserXL = styled(BlockedUserPlaceholderXl)<
  IBlockedUserIconProps
>`
  ${ImageStyle}
`;
export const BlockedUserL = styled(BlockedUserPlaceholderL)<
  IBlockedUserIconProps
>`
  ${ImageStyle}
`;
export const BlockedUserM = styled(BlockedUserPlaceholderM)<
  IBlockedUserIconProps
>`
  ${ImageStyle}
`;
export const BlockedUserS = styled(BlockedUserPlaceholderS)<
  IBlockedUserIconProps
>`
  ${ImageStyle}
`;
export const BlockedUserXS = styled(BlockedUserPlaceholderXs)<
  IBlockedUserIconProps
>`
  ${ImageStyle}
`;

export const BlackedUserPlaceholder = ({
  size,
  elementPaletteProps,
}: IBlockedUserPlaceholder) => {
  switch (size) {
    case "xl":
      return <BlockedUserXL elementPaletteProps={elementPaletteProps} />;
    case "l":
      return <BlockedUserL elementPaletteProps={elementPaletteProps} />;
    case "m":
      return <BlockedUserM elementPaletteProps={elementPaletteProps} />;
    case "s":
      return <BlockedUserS elementPaletteProps={elementPaletteProps} />;
    case "xs":
      return <BlockedUserXS elementPaletteProps={elementPaletteProps} />;
    default:
      return null;
  }
};
