import { CancelToken } from "axios";
import { ThunkPromiseResult } from "app/store";
import { ActionUnion } from "./helpers";
import { ComponentLayoutTypes } from "./types";

function createAction<T extends { type: ComponentLayoutTypes }>(d: T): T {
  return d;
}
export const ActionCreators = {
  updateComponentLayout: (payload: {
    id: string;
    layout: Moim.Component.IComponentLayout | null;
  }) =>
    createAction({
      type: ComponentLayoutTypes.UPDATE_COMPONENT_LAYOUT,
      payload,
    }),
};

export type Actions = ActionUnion<typeof ActionCreators>;

export function getComponentLayout(
  id: Moim.Id,
  type: string,
  platform?: string,
  cancelToken?: CancelToken,
): ThunkPromiseResult<Moim.Component.IComponentLayout | undefined> {
  return async (dispatch, getState, { apiSelector }) => {
    const entityId = `${id}|${type}${platform ? `|${platform}` : ""}`;
    try {
      const result = (
        await apiSelector(
          getState(),
          dispatch,
        ).componentLayout.getComponentLayout(id, type, platform, cancelToken)
      ).data;

      dispatch(
        ActionCreators.updateComponentLayout({
          id: entityId,
          layout: result,
        }),
      );

      return result;
    } catch (err) {
      dispatch(
        ActionCreators.updateComponentLayout({
          id: entityId,
          layout: null,
        }),
      );
    }
  };
}
