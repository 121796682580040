import { Svg18NotiB, Svg18Messagesolid, Svg18Timersolid, Svg18CartFull } from "@moim/icons";
import styled, { css } from "styled-components";





import { px2rem } from "common/helpers/rem";

import {
  FlatButton,
  GhostButton,
  TextButton,
} from "common/components/designSystem/buttons";
import { ThemeColorScaleTypes } from "app/enums";
import { TopNaviBlockPropertyStyle } from "../section/styled";

function getTextColorScale(value?: Moim.Blockit.V2.ColorValue) {
  switch (value) {
    case ThemeColorScaleTypes.BLACK:
      return ThemeColorScaleTypes.WHITE;
    case ThemeColorScaleTypes.WHITE:
      return ThemeColorScaleTypes.BLACK;
    default:
      return value;
  }
}

export const Wrapper = styled.div<{
  element?: Moim.Layout.Navigation.CurrentUserElementType;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  min-height: 0;

  > :last-child {
    margin-right: ${px2rem(16)};
  }

  ${TopNaviBlockPropertyStyle}

  button + button {
    margin-left: ${px2rem(16)};
  }

  > button:last-of-type {
    margin-right: ${px2rem(12)};
  }

  ${props => css`
    --signup-tint-color-800: ${props.theme.getBlockitColorValue(
      props.element?.signUpButtonStyle?.background ?? "",
      "grey800",
    )};

    // NOTE: purpose for Flat button text
    --signup-tint-text-color: ${props.theme.getBlockitColorValue(
      getTextColorScale(props.element?.signUpButtonStyle?.textColor) ?? "",
      "white800",
    )};
  `};

  ${props => css`
    --signin-tint-color-800: ${props.theme.getBlockitColorValue(
      props.element?.signInButtonStyle?.background ?? "",
      "grey800",
    )};

    // NOTE: purpose for Flat button text
    --signin-tint-text-color: ${props.theme.getBlockitColorValue(
      getTextColorScale(props.element?.signInButtonStyle?.textColor) ?? "",
      "white800",
    )};
  `};

  ${props =>
    props.element?.iconColor
      ? `--icon-color-600: ${props.theme.getBlockitColorValue(
          props.element?.iconColor,
          "grey600",
        )};

        --icon-color-800: ${props.theme.getBlockitColorValue(
          props.element?.iconColor,
          "grey800",
        )};
        `
      : null};
`;

export const ItemWrapper = styled.div.attrs({ role: "button" })<{
  selected: boolean;
}>`
  display: inline-flex;
  transition: background-color 200ms ease-in;
  border-radius: ${px2rem(2)};
  position: relative;
  ${props =>
    props.selected &&
    css`
      background-color: ${props.theme.colorV2.colorSet.fog50};
    `};
  &:hover {
    background-color: ${props => props.theme.colorV2.colorSet.fog50};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  & > ${ItemWrapper} {
    min-width: ${px2rem(38)};
    max-width: ${px2rem(42)};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const NotiIcon = styled(Svg18NotiB).attrs({
  size: "xs",
  touch: 30,
  role: "button",
})`
  path {
    fill: var(
      --icon-color-800,
      ${props => props.theme.colorV2.colorSet.fog800}
    );
  }
`;

export const DMIcon = styled(Svg18Messagesolid).attrs({
  size: "xs",
  touch: 30,
  role: "button",
})`
  path {
    fill: var(
      --icon-color-800,
      ${props => props.theme.colorV2.colorSet.fog800}
    );
  }
`;

export const PeriodIcon = styled(Svg18Timersolid).attrs({
  size: "xs",
  touch: 30,
  role: "button",
})`
  g {
    fill: var(
      --icon-color-800,
      ${props => props.theme.colorV2.colorSet.fog800}
    );
  }
`;

export const MyCartIcon = styled(Svg18CartFull).attrs({
  size: "xs",
  touch: 30,
  role: "button",
})`
  g {
    fill: var(
      --icon-color-800,
      ${props => props.theme.colorV2.colorSet.fog800}
    );
  }
`;

export const CurrentUserWrapper = styled(ItemWrapper)`
  display: inline-flex;
  align-items: center;
  height: ${px2rem(42)};
  padding: 0 ${px2rem(8)};
`;

export const NotiAlertBadgeStyle = css`
  position: absolute;
  top: ${px2rem(-2)};
  left: ${px2rem(18)};
  box-shadow: 0 ${px2rem(2)} ${px2rem(4)} 0
    ${props => props.theme.colorV2.colorSet.grey200};
`;

export const buttonFactory = (
  type: Moim.Layout.Navigation.IButtonStyle["type"],
) => {
  switch (type) {
    default:
    case "FLAT":
      return ThemedFlatButton;
    case "GHOST":
      return ThemedGhostButton;
    case "TEXT":
      return ThemedTextButton;
  }
};

const ThemedTextButton = styled(TextButton)`
  &.signup {
    color: var(
      --signup-tint-color-800,
      ${props => props.theme.colorV2.colorSet.fog800}
    );
  }

  &.signin {
    color: var(
      --signin-tint-color-800,
      ${props => props.theme.colorV2.colorSet.fog800}
    );
  }
`;

const ThemedGhostButton = styled(GhostButton)`
  &.signup {
    color: var(
      --signup-tint-color-800,
      ${props => props.theme.colorV2.colorSet.fog800}
    );
    border: ${px2rem(1)} solid
      var(
        --signup-tint-color-800,
        ${props => props.theme.colorV2.colorSet.fog800}
      );
  }

  &.signin {
    color: var(
      --signin-tint-color-800,
      ${props => props.theme.colorV2.colorSet.fog800}
    );
    border: ${px2rem(1)} solid
      var(
        --signin-tint-color-800,
        ${props => props.theme.colorV2.colorSet.fog800}
      );
  }

  border-radius: ${px2rem(4)};
  background-color: rgba(0, 0, 0, 0);
`;

const ThemedFlatButton = styled(FlatButton)<{
  signUpbuttonScale: Moim.Enums.ThemeColorScaleType;
}>`
  ${props => {
    return css`
      &.signup {
        background-color: var(
          --signup-tint-color-800,
          ${props.theme.colorV2.colorSet.fog800}
        );
        border-color: var(
          --signup-tint-color-800,
          ${props.theme.colorV2.colorSet.fog800}
        );

        color: var(
          --signup-tint-text-color,
          ${() => {
            switch (props.signUpbuttonScale) {
              case ThemeColorScaleTypes.BLACK:
                return props.theme.colorV2.colorSet.white800;

              case ThemeColorScaleTypes.WHITE:
                return props.theme.colorV2.colorSet.grey800;
            }
          }}
        );
      }

      &.signin {
        background-color: var(
          --signin-tint-color-800,
          ${props.theme.colorV2.colorSet.fog800}
        );
        border-color: var(
          --signin-tint-color-800,
          ${props.theme.colorV2.colorSet.fog800}
        );

        color: var(
          --signin-tint-text-color,
          ${() => {
            switch (props.signUpbuttonScale) {
              case ThemeColorScaleTypes.BLACK:
                return props.theme.colorV2.colorSet.white800;

              case ThemeColorScaleTypes.WHITE:
                return props.theme.colorV2.colorSet.grey800;
            }
          }}
        );
      }
    `;
  }}

  border-radius: ${px2rem(4)};
`;
