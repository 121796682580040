import ThumbnailCarousel from "app/common/components/thread/commonThreadItem/components/common/thumbnailCarousel";
import { convertMediaBlocksToCarouselBlock } from "common/components/thread/commonThreadItem/components/common/thumbnailCarousel/helper";
import ThumbnailGrid, {
  THUMBNAIL_GRID_IMAGE_LIMIT,
} from "common/components/thread/commonThreadItem/components/common/thumbnailGrid";
import ThumbnailLock from "common/components/thread/commonThreadItem/components/common/thumbnailLock";
import { ThreadThumbnailWrapper } from "common/components/thread/commonThreadItem/components/common/wrapper/thumbnail";
import { px2rem } from "common/helpers/rem";
import useIsMobile from "common/hooks/useIsMobile";
import React from "react";
import styled from "styled-components";

const MediaWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  padding-inline: ${(props) => (!props.isMobile ? px2rem(16) : 0)};

  & > div {
    border-radius: ${px2rem(6)};
  }
`;

const StyledImage = styled.img.attrs({ loading: "lazy", alt: "" })`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CarouselWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface IProps {
  preview?: Moim.Forum.IThreadPreview;
  mediaBlocks?: Moim.Blockit.V2.IImageBlock[];
  mediaBlocksCount?: number;
  thumbnailConfig?: Moim.Blockit.V2.IPostListShowConfig["thumbnailConfig"];
  mediaDisplayType?: Moim.Blockit.V2.IPostListShowConfig["mediaDisplayType"];
  showContent?: boolean;
}

const Media: React.FC<IProps> = ({
  preview,
  mediaBlocks,
  mediaBlocksCount,
  mediaDisplayType = "grid",
  showContent,
}) => {
  const ratio = "1:1";
  const exceptionalRatio = "6:5";
  const radius = 0;
  const isMobile = useIsMobile();

  const carouselBlock = convertMediaBlocksToCarouselBlock(mediaBlocks ?? []);

  const thumbnailElement = React.useMemo(() => {
    if (!showContent) {
      return (
        <ThumbnailLock
          mediaBlocksCount={mediaBlocksCount ?? 0}
          {...carouselBlock}
        />
      );
    }

    switch (mediaDisplayType) {
      case "grid":
        return (
          <ThumbnailGrid
            mediaBlocksCount={mediaBlocksCount ?? 0}
            {...carouselBlock}
          />
        );
      case "carousel":
      default:
        return (
          <ThumbnailCarousel
            mediaBlocksCount={mediaBlocksCount ?? 0}
            {...carouselBlock}
          />
        );
    }
  }, [carouselBlock, mediaBlocksCount, mediaDisplayType, showContent]);

  if (!preview?.thumbnail) {
    return <></>;
  }

  return (
    <MediaWrapper isMobile={isMobile}>
      <ThreadThumbnailWrapper
        ratio={
          mediaDisplayType === "grid" &&
          carouselBlock.images.length > THUMBNAIL_GRID_IMAGE_LIMIT - 1
            ? exceptionalRatio
            : ratio
        }
        thumbnail={preview?.thumbnail}
        isVideo={preview?.isVideo}
        radius={radius}
      >
        {(src, srcSet) =>
          src || srcSet ? (
            carouselBlock.images.length > 0 ? (
              <CarouselWrapper>{thumbnailElement}</CarouselWrapper>
            ) : (
              // below case is needed when the thread has video resources only.
              <StyledImage src={src} srcSet={srcSet} />
            )
          ) : (
            <></>
          )
        }
      </ThreadThumbnailWrapper>
    </MediaWrapper>
  );
};

export default React.memo(Media);
