import { withPlacement } from "../../hoc/withPlacement";
import RawHlsVideo from "common/components/hlsVideo";
import { MEDIA_QUERY } from "common/constants/responsive";
import * as React from "react";
import styled, { css } from "styled-components";

const VideoContainer = styled.div<{
  ratio?: string;
}>`
  ${(props) => {
    if (props.ratio) {
      const rW = parseInt(props.ratio.split(":")[0], 10);
      const rH = parseInt(props.ratio.split(":")[1], 10);

      return css`
        position: relative;
        width: 100%;
        height: 0;
        padding-top: ${(100 * (rH / rW)).toFixed(2)}%;

        > div {
          width: 100%;
          position: absolute;
          inset: 0;

          div[class^="Wrapper"] {
          div[class^="Media"] {
            width: 100%;
            height: 100%;
          }

          @media ${MEDIA_QUERY.ONLY_MOBILE}{
            div[class^="Media"] {
              padding: 0;
            }
          }
        }

        .video-js {
          height: 100%;
        }

        video,
        .vjs-poster > img {
         object-fit: cover;
        }
      `;
    }

    return css`
      width: 100%;
      height: fit-content;
    `;
  }}
`;

const VideoBlock: React.FC<Moim.Blockit.V2.IVideoBlock> = ({
  mimeType,
  sourceUrl,
  metaData,
  playerConfig,
}) => {
  const width = metaData?.width ?? 160;
  const height = metaData?.height ?? 90;

  const handleVideoEnded: React.ReactEventHandler<HTMLVideoElement> =
    React.useCallback(() => {
      // NOTE: TBD
    }, []);

  return (
    <VideoContainer
      ratio={
        metaData?.ratio ?? `${metaData?.width ?? 16}:${metaData?.height ?? 9}`
      }
    >
      <RawHlsVideo
        sources={
          sourceUrl
            ? [
                {
                  type: mimeType,
                  src: sourceUrl,
                },
              ]
            : []
        }
        poster={metaData?.poster}
        width={width}
        height={height}
        staticRatio="unset"
        loop={playerConfig?.loop}
        autoPlay={playerConfig?.autoPlay}
        controls={Boolean(playerConfig?.showControls)}
        muted={playerConfig?.autoPlay ? true : playerConfig?.initialMute}
        disableMuteButton={playerConfig?.disableMuteButton}
        onEnded={playerConfig?.loop ? undefined : handleVideoEnded}
      />
    </VideoContainer>
  );
};

export default withPlacement(VideoBlock);
