import { useCallback, useEffect } from "react";
import { useActions, useStoreState } from "app/store";
import useCancelToken from "./useCancelToken";
import useRedirect from "./useRedirect";
import useSideNavigationPanel from "./useSideNavigationPanel";
import { useOpenStateWithHashRoute } from "./useOpenState";

import {
  ActionCreators as DirectMessageDialogActionCreators,
  createDirectMessage,
} from "app/actions/directMessage";
import { MoimURL } from "common/helpers/url";

const HASH_KEY = "newDirectMessage";

function useNewDirectMessageDialog() {
  const { storeOpenStatus } = useStoreState(state => ({
    storeOpenStatus: state.newDirectMessageDialog.open,
  }));

  const {
    openNewDirectMessageDialog,
    closeNewDirectMessageDialog,
    dispatchCreateDirectMessage,
  } = useActions({
    openNewDirectMessageDialog:
      DirectMessageDialogActionCreators.openNewDirectMessageDialog,
    closeNewDirectMessageDialog:
      DirectMessageDialogActionCreators.closeNewDirectMessageDialog,
    dispatchCreateDirectMessage: createDirectMessage,
  });
  const cancelToken = useCancelToken();
  const redirect = useRedirect();
  const { collapseSideNavigation } = useSideNavigationPanel();

  const { open, close } = useOpenStateWithHashRoute(HASH_KEY, {
    onHashDismiss: closeNewDirectMessageDialog,
  });

  const handleCreateDirectMessage = useCallback(
    async (userIds: Moim.Id[]) => {
      try {
        const createdDirectMessageId = await dispatchCreateDirectMessage(
          { direct_message: { invitees: userIds } },
          cancelToken.current.token,
        );
        redirect(
          new MoimURL.DirectMessageShow({
            directMessageId: createdDirectMessageId || "",
          }).toString(),
        );
        closeNewDirectMessageDialog();
        collapseSideNavigation();
      } catch {}
    },
    [
      cancelToken,
      closeNewDirectMessageDialog,
      collapseSideNavigation,
      dispatchCreateDirectMessage,
      redirect,
    ],
  );

  const handleClose = useCallback(() => {
    close();
    closeNewDirectMessageDialog();
  }, []);

  useEffect(() => {
    if (storeOpenStatus) {
      open();
    }
  }, [storeOpenStatus]);

  return {
    open: storeOpenStatus,
    handleCreateDirectMessage,
    openNewDirectMessageDialog,
    closeNewDirectMessageDialog: handleClose,
  };
}

export default useNewDirectMessageDialog;
