import { Svg18Twitter } from "@moim/icons";
import * as React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { TwitterShareButton } from "react-share";

import SNSShareItem from "..";

import { useStoreState } from "app/store";
import { AnalyticsClass } from "common/helpers/analytics/analytics";

export const TwitterIcon = styled(Svg18Twitter).attrs({
  role: "button",
  size: "xs",
})``;

export default function TwitterShare() {
  const { shareUrl } = useStoreState(state => ({
    shareUrl: state.SNSShareDialog.shareUrl,
  }));

  const handleClickShare = React.useCallback(() => {
    if (shareUrl) {
      AnalyticsClass.getInstance().shareUrl({
        destination: "twitter",
        url: shareUrl,
      });
    }
  }, [shareUrl]);

  if (!shareUrl) {
    return null;
  }

  return (
    <TwitterShareButton url={shareUrl} onClick={handleClickShare}>
      <SNSShareItem
        icon={<TwitterIcon />}
        text={<FormattedMessage id="external_service_twitter" />}
      />
    </TwitterShareButton>
  );
}
