import * as React from "react";
import { Redirect, RouteComponentProps, RouteProps } from "react-router-dom";
import { VingleLoadable } from "app/routes/loadable";
// URL Definition
import createURLDefinition from "common/helpers/url/createDefinition";
import { MoimURL } from "common/helpers/url";
// SSR loadData
import {
  CategoryLoadData,
  DeliveryGroupLoadData,
  ProductSetLoadData,
  ProductShowLoadData,
  ProductShowThreadLoadData,
  SellerLoadData,
} from "app/modules/commerce/ssrLoadData";
import { ForumLoadData } from "app/modules/forum/containers/forumShow/ssrLoadData";

// helpers
import { ChannelLoadData } from "app/modules/channel/ssrLoadData";
import {
  CategoryShowHelmet,
  DeliveryGroupShowHelmet,
  ProductSetShowHelmet,
  ProductShowHelmet,
  ProductShowThreadHelmet,
  SellerShowHelmet,
} from "app/modules/commerce/helmets";
import {
  CryptoBadgeCertificateShowSSRHelmet,
  CryptoBadgeGroupShowSSRHelmet,
  CryptoBadgeShowSSRHelmet,
} from "app/modules/cryptobadge/helmets";
import {
  CryptoBadgeCertificateShowLoadData,
  CryptoBadgeGroupShowLoadData,
  CryptoBadgeShowLoadData,
} from "app/modules/cryptobadge/ssrLoadData";
import { NFTCollectionShowHelmet } from "app/modules/nftCollection/helmets";
import { NFTCollectionLoadData } from "app/modules/nftCollection/ssrLoadData";
import { NFTCollectionScheduleShowHelmet } from "app/modules/nftSchedule/helmets";
import { NFTScheduleShowLoadData } from "app/modules/nftSchedule/ssrLoadData";
import { NFTSetShowSSRHelmet } from "app/modules/nftSet/helmets";
import { NFTSetShowLoadData } from "app/modules/nftSet/ssrLoadData";
import { NFTShowHelmet } from "app/modules/nftShow/helmets";
import { NFTShowLoadData } from "app/modules/nftShow/ssrLoadData";
import { PositionShowHelmet } from "app/modules/profile/components/positions/helmets";
import { UserShowHelmet } from "app/modules/user/helmets";
import { ViewLoadData } from "app/modules/view/ssrLoadData";
import { ISSRLoadDataPayload } from "app/typings/ssr";

// Components
const DQuestShowModalRedirect = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/dquest/containers/show/redirector"),
);
const CommerceCartsRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/redirectToSecondaryView/commerceCart"),
);
const CommerceMyShoppingRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/redirectToSecondaryView/commerceMyShopping"),
);
const MyQuestsRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/redirectToSecondaryView/myQuest"),
);
const ShareCommunityCoinsToBeExpiredRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/redirectToSecondaryView/shareCommunityCoinToBeExpired"),
);
const ShareCommunityCoinsRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/redirectToSecondaryView/shareCommunityCoins"),
);
const SharePositionMembersRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/redirectToSecondaryView/sharePositionMembers"),
);
const SharingRedirectDetector = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/sharingRedirectDetector"),
);
const Channel = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/channel"),
);
const Home = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/home"),
);
const ExternalCommunity = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/externalPreview"),
);
const NotFound = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/common/components/feedBack/components/notFound"),
);
const ServerMaintenance = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/common/components/feedBack/components/serverMaintenance"),
);
const ConnectionError = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/common/components/feedBack/components/connectionError"),
);
const NoRight = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/common/components/feedBack/components/noRight"),
);
const MoimLogin = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/login"),
);
const About = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/termsAndPolicy"),
);
const Hub = VingleLoadable(/* #__LOADABLE__ */ async () => import("app/hub"));
const PersonalSetting = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/personalSetting"),
);
const Cover = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/cover"),
);
const Search = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/search"),
);
const PluginRedirectDetector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("common/components/pluginRedirectDetector"),
);
const MeetingHome = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/meeting"),
);
const CommerceMain = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/commerce"),
);
const CampaignMain = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/campaign"),
);
const TestBed = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/testBed"),
);
const PostTemplate = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/postTemplate"),
);
const ExternalMoimBlockitEditor = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/externalMoimBlockitEditor"),
);
const ExternalMoimBlockitEditorV2 = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/externalMoimBlockitEditor.v2"),
);
const Plugins = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/plugins"),
);
const ContentsGroupThreads = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/contentsGroup"),
);
const NftShow = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/nftShow"),
);
const NftSetShow = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/nftSet"),
);
const NftScheduleShow = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/nftSchedule"),
);
const NftCollectionShow = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/nftCollection"),
);
const TreasuryShow = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/treasury"),
);
const ChildMoimGroupThreads = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/childMoimGroup"),
);
const BlockitEditorMobileRedirect = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/blockitEditor/container/mobileRedirect"),
);
const BlockitEditor = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/blockitEditor/container/withDraftCreate"),
);
const QuestList = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/dquest/containers/list"),
);
const QuestGroupList = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/dquest/containers/questGroup"),
);
const CryptobadgeShow = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/cryptobadge/cryptobadgeShow"),
);
const CertificateShow = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/cryptobadge/certificate/certificateShow"),
);
const CertificateMetadataShow = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/cryptobadge/certificate/certificateMetadataShow"),
);
const CryptobadgeGroupShow = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/cryptobadge/cryptobadgeGroupShow"),
);
const UserPage = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/user"),
);
const UserThreadPage = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/user/container/threads"),
);
const GuestPaymentSearch = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/commerce/containers/guestPaymentSearch"),
);
const ThreadShowPageRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/postShow/modal/redirector"),
);
const MoimMembersRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import(
      "app/modules/secondaryView/native/components/moimMembers/redirector"
    ),
);
const UserBookmarkRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/secondaryView/native/components/bookmark/redirector"),
);
const UserUnlockContentsRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import(
      "app/modules/secondaryView/native/components/unlockContents/redirector"
    ),
);
const ConversationInformationRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import(
      "app/modules/secondaryView/native/components/conversationInformation/redirector"
    ),
);
const ForumInformationRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import(
      "app/modules/secondaryView/native/components/forumInformation/redirector"
    ),
);
const UserNFTListRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/secondaryView/native/components/userNfts/redirector"),
);
const UserCertificateListRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import(
      "app/modules/secondaryView/native/components/userCertificates/redirector"
    ),
);
const ProfileEditorRedirector = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/profileEditor/redirector"),
);

export interface IRouteConfig extends RouteProps {
  component?: React.ComponentType<IRouteComponentProps>;
  def?:
    | ReturnType<typeof createURLDefinition>
    | ReturnType<typeof createURLDefinition>[];
  exact?: boolean;
  strict?: boolean;
  layoutType?: Moim.ILayoutType; // Note: default value is "base"
  mobileLayoutType?: Moim.ILayoutType; // Note: default value is "base"
  routes?: IRouteConfig[];
  loadData?: (payload: ISSRLoadDataPayload) => Promise<void>;
  allowSSR?: boolean;
  helmets?: Record<
    string,
    (props: IRouteComponentProps) => React.ReactNode | null
  >;
}

export interface IRouteComponentProps
  extends RouteComponentProps<Moim.IMatchParams> {
  routes?: IRouteConfig[];
}

// 어느 url에서나 접근 가능한 route
export const commonRoutes: IRouteConfig[] = [
  {
    def: MoimURL.ExternalMoimBlockitEditor,
    layoutType: "full-screen",
    exact: true,
    component: ExternalMoimBlockitEditor,
  },
  {
    def: MoimURL.ExternalMoimBlockitEditorV2,
    layoutType: "full-screen",
    exact: true,
    component: ExternalMoimBlockitEditorV2,
  },
  {
    def: [MoimURL.About, MoimURL.AboutPolicy, MoimURL.AboutTerms],
    layoutType: "full-screen",
    component: About,
  },

  {
    def: MoimURL.NotFound,
    layoutType: "full-screen",
    component: NotFound,
  },

  {
    def: MoimURL.ServerMaintenance,
    layoutType: "full-screen",
    component: ServerMaintenance,
  },

  {
    def: MoimURL.ConnectionError,
    layoutType: "full-screen",
    component: ConnectionError,
  },
  {
    def: MoimURL.MeetingHome,
    layoutType: "full-screen",
    exact: true,
    component: MeetingHome,
  },
  {
    def: MoimURL.NoRight,
    component: NoRight,
  },
  {
    component: () => <Redirect to={MoimURL.NotFound.pattern} />,
  },
];

// 허브 url(서브 도메인이 없는 url)에서 접근 가능한 route
export const hubRoutes: IRouteConfig[] = [
  // NOTE: 시어스랩 관련 external Moim ( custom 주소 쓸 일이 없음 )
  {
    def: [
      MoimURL.ExternalCommunity,
      MoimURL.EXUserShow,
      MoimURL.EXUserNFTList,
      MoimURL.EXNFTShow,
      MoimURL.EXCoinShow,
    ],
    // 제일 큰 라우트에서 원래는 모든 route의 경우의수를 배열로 정의해두어야함
    component: ExternalCommunity,
  },
  {
    def: MoimURL.HubHome,
    component: Hub,
  },
  // moim app home은 맨 밑에 위치해야됨
  {
    def: MoimURL.MoimAppHome,
    component: () => <Redirect to={MoimURL.HubHome.pattern} />,
    exact: true,
  },
];

// 모임 url(서브 도메인이 있는 url)에서 접근 가능한 route
export const moimRoutes: IRouteConfig[] = [
  {
    def: MoimURL.CoverPage,
    layoutType: "full-screen",
    component: Cover,
  },
  {
    def: MoimURL.BlockitEditor,
    layoutType: "no-gnb",
    exact: true,
    component: (routeProps: IRouteComponentProps) => (
      <BlockitEditorMobileRedirect {...routeProps}>
        <BlockitEditor {...routeProps} />
      </BlockitEditorMobileRedirect>
    ),
  },
  {
    def: [
      MoimURL.PersonalSettingProfile,
      MoimURL.PersonalSettingMyWallet,
      MoimURL.PersonalSettingNotificationMoim,
      MoimURL.PersonalSettingNotificationService,
      MoimURL.PersonalSettingNotificationMarketing,
      MoimURL.PersonalSettingMoimLeave,
      MoimURL.PersonalSettingPrivacy,
      MoimURL.PersonalSettingSignUpForm,

      MoimURL.PersonalSettingMoim,
    ],
    layoutType: "no-gnb",
    mobileLayoutType: "full-screen",
    component: PersonalSetting,
  },

  {
    def: MoimURL.ShowThread,
    exact: true,
    mobileLayoutType: "full-screen",
    component: ThreadShowPageRedirector,
  },

  // WITH LAYOUT
  {
    def: MoimURL.ContentsGroupThreads,
    component: ContentsGroupThreads,
  },
  {
    def: MoimURL.ChildMoimGroupMoims,
    component: ChildMoimGroupThreads,
  },
  {
    def: [
      MoimURL.MyQuestList,
      MoimURL.MyQuestListInActive,
      MoimURL.MyQuestListAchieved,
    ],
    exact: true,
    mobileLayoutType: "full-screen",
    component: MyQuestsRedirector,
  },
  {
    def: MoimURL.QuestGroupQuests,
    exact: true,
    component: QuestGroupList,
  },

  {
    def: MoimURL.QuestShow,
    mobileLayoutType: "full-screen",
    exact: true,
    component: DQuestShowModalRedirect,
  },
  {
    def: MoimURL.QuestList,
    component: QuestList,
  },
  {
    def: [MoimURL.ShowForumThread, MoimURL.Forum],
    component: Channel,
    exact: true,
    allowSSR: true,
    loadData: ForumLoadData,
  },
  {
    def: MoimURL.ViewShow,
    component: Channel,
    exact: true,
    allowSSR: true,
    loadData: ViewLoadData,
  },

  {
    def: MoimURL.ConversationMembers,
    exact: true,
    mobileLayoutType: "full-screen",
    component: ConversationInformationRedirector,
  },

  {
    def: MoimURL.ForumMembers,
    exact: true,
    mobileLayoutType: "full-screen",
    component: ForumInformationRedirector,
  },

  {
    def: [
      MoimURL.FocusedShowSubReply,
      MoimURL.CreateForumThread,
      MoimURL.EditForumThread,
      MoimURL.FocusedShowForumThread,
      MoimURL.ConversationShow,
      MoimURL.DirectMessageShow,
      MoimURL.SubMoimList,
    ],
    component: Channel,
    loadData: ChannelLoadData,
  },
  {
    def: [MoimURL.PluginShow, MoimURL.Plugins],
    exact: true,
    component: Plugins,
  },
  {
    def: MoimURL.CommercePaymentsShow,
    mobileLayoutType: "full-screen",
    component: CommerceMyShoppingRedirector,
  },
  {
    def: [
      MoimURL.CommercePaymentsList,
      MoimURL.CommerceMyBenefitCoupons,
      MoimURL.CommerceMyBenefitCouponsFocus,
      MoimURL.CommerceMyBenefit,
      MoimURL.CommerceMyPreference,
      MoimURL.CommerceMyPreferenceAddressManage,
      MoimURL.CommerceMyPreferencePaymentManage,
      MoimURL.CommerceMyShopping,
    ],
    mobileLayoutType: "full-screen",
    component: CommerceMyShoppingRedirector,
  },
  {
    def: [MoimURL.CommerceMyCarts, MoimURL.CommerceMyCartsWishlist],
    mobileLayoutType: "full-screen",
    component: CommerceCartsRedirector,
  },

  {
    def: [MoimURL.ProfileBadgeList, MoimURL.ProfileBadgeCardList],
    mobileLayoutType: "full-screen",
    exact: true,
    component: UserCertificateListRedirector,
  },
  {
    def: MoimURL.ProfileNftList,
    mobileLayoutType: "full-screen",
    exact: true,
    component: UserNFTListRedirector,
  },
  {
    def: MoimURL.UserThreadList,
    exact: true,
    component: UserThreadPage,
  },
  {
    def: MoimURL.ProfileEdit,
    exact: true,
    mobileLayoutType: "full-screen",
    component: ProfileEditorRedirector,
  },
  {
    def: [MoimURL.Me, MoimURL.ProfileShare, MoimURL.ProfileShare],
    mobileLayoutType: "full-screen",
    component: UserPage,
    helmets: {
      [MoimURL.ProfileShare.pattern]: UserShowHelmet,
    },
  },
  {
    def: MoimURL.CoinShow,
    mobileLayoutType: "full-screen",
    exact: true,
    component: ShareCommunityCoinsRedirector,
  },
  {
    def: MoimURL.CoinToBeExpired,
    mobileLayoutType: "full-screen",
    component: ShareCommunityCoinsToBeExpiredRedirector,
  },
  {
    def: MoimURL.PositionMembers,
    mobileLayoutType: "full-screen",
    component: SharePositionMembersRedirector,
    helmets: {
      [MoimURL.PositionMembers.pattern]: PositionShowHelmet,
    },
  },

  {
    def: [
      MoimURL.PostTemplate,
      MoimURL.PostTemplateEditor,
      MoimURL.PostTemplateShow,
    ],
    component: PostTemplate,
  },
  {
    def: [MoimURL.SearchWithTab, MoimURL.Search],
    exact: true,
    component: (routeProps: IRouteComponentProps) => <Search {...routeProps} />,
  },

  {
    def: [
      MoimURL.CampaignWallet,
      MoimURL.CampaignParticipants,
      MoimURL.CampaignFunds,
      MoimURL.CampaignExecutionView,
      MoimURL.CampaignExecutions,
    ],
    component: CampaignMain,
  },
  {
    def: [
      MoimURL.CommerceGuestPaymentSearchWithId,
      MoimURL.CommerceGuestPaymentSearch,
    ],

    component: GuestPaymentSearch,
  },
  {
    def: [
      MoimURL.CommerceProductShowReview,
      MoimURL.CommerceProductShowReviewReply,
      MoimURL.CommerceProductShowQnA,
      MoimURL.CommerceProductShowQnAReply,
      MoimURL.CommerceProductShowComment,
      MoimURL.CommerceProductShowCommentReply,
    ],
    component: CommerceMain,
    helmets: {
      [MoimURL.CommerceProductShowReview.pattern]: ProductShowThreadHelmet,
      [MoimURL.CommerceProductShowQnA.pattern]: ProductShowThreadHelmet,
      [MoimURL.CommerceProductShowComment.pattern]: ProductShowThreadHelmet,
    },
    loadData: ProductShowThreadLoadData,
  },
  {
    def: MoimURL.CommerceProductShow,
    allowSSR: true,
    component: CommerceMain,
    helmets: {
      [MoimURL.CommerceProductShow.pattern]: ProductShowHelmet,
    },
    loadData: ProductShowLoadData,
  },
  {
    def: MoimURL.CommerceProductSets,
    component: CommerceMain,
    helmets: {
      [MoimURL.CommerceProductSets.pattern]: ProductSetShowHelmet,
    },
    loadData: ProductSetLoadData,
  },
  {
    def: [MoimURL.CommerceCategories, MoimURL.CommerceCategoryAll],
    component: CommerceMain,
    helmets: {
      [MoimURL.CommerceCategories.pattern]: CategoryShowHelmet,
    },
    loadData: CategoryLoadData,
  },
  {
    def: MoimURL.CommerceSellers,
    component: CommerceMain,
    helmets: {
      [MoimURL.CommerceSellers.pattern]: SellerShowHelmet,
    },
    loadData: SellerLoadData,
  },
  {
    def: MoimURL.CommerceDeliveryGroup,
    component: CommerceMain,
    helmets: {
      [MoimURL.CommerceDeliveryGroup.pattern]: DeliveryGroupShowHelmet,
    },
    loadData: DeliveryGroupLoadData,
  },
  {
    def: [
      MoimURL.CommerceSellerSets,
      MoimURL.CommerceBrandShow,
      MoimURL.CommerceBrandList,
    ],
    component: CommerceMain,
  },

  {
    def: MoimURL.MoimMembers,
    exact: true,
    mobileLayoutType: "full-screen",
    component: MoimMembersRedirector,
  },

  {
    def: MoimURL.UserBookmark,
    mobileLayoutType: "full-screen",
    component: UserBookmarkRedirector,
  },

  {
    def: MoimURL.UserUnlockedContents,
    mobileLayoutType: "full-screen",
    component: UserUnlockContentsRedirector,
  },

  {
    def: MoimURL.NftShow,
    component: () => <NftShow />,
    helmets: {
      [MoimURL.NftShow.pattern]: NFTShowHelmet,
    },
    loadData: NFTShowLoadData,
  },
  {
    def: MoimURL.NftSetShow,
    component: NftSetShow,
    helmets: {
      [MoimURL.NftSetShow.pattern]: NFTSetShowSSRHelmet,
    },
    loadData: NFTSetShowLoadData,
  },

  {
    def: MoimURL.NftScheduleShow,
    component: NftScheduleShow,
    helmets: {
      [MoimURL.NftScheduleShow.pattern]: NFTCollectionScheduleShowHelmet,
    },
    loadData: NFTScheduleShowLoadData,
  },

  {
    def: [MoimURL.NftCollectionShow, MoimURL.NftCollectionShowTabs],
    exact: true,
    component: NftCollectionShow,
    helmets: {
      [MoimURL.NftCollectionShow.pattern]: NFTCollectionShowHelmet,
      [MoimURL.NftCollectionShowTabs.pattern]: NFTCollectionShowHelmet,
    },
    loadData: NFTCollectionLoadData,
  },

  {
    def: [MoimURL.TreasuryShow],
    exact: true,
    component: TreasuryShow,
  },

  {
    def: [MoimURL.CryptobadgeShow],
    exact: true,
    component: CryptobadgeShow,
    helmets: {
      [MoimURL.CryptobadgeShow.pattern]: CryptoBadgeShowSSRHelmet,
    },
    loadData: CryptoBadgeShowLoadData,
  },

  {
    def: [MoimURL.CertificateShow],
    exact: true,
    component: CertificateShow,
    helmets: {
      [MoimURL.CertificateShow.pattern]: CryptoBadgeCertificateShowSSRHelmet,
    },
    loadData: CryptoBadgeCertificateShowLoadData,
  },
  {
    def: [MoimURL.CertificateMetadataShow],
    exact: true,
    component: CertificateMetadataShow,
  },

  {
    def: [MoimURL.CryptobadgeGroupShow],
    exact: true,
    component: CryptobadgeGroupShow,
    helmets: {
      [MoimURL.CryptobadgeGroupShow.pattern]: CryptoBadgeGroupShowSSRHelmet,
    },
    loadData: CryptoBadgeGroupShowLoadData,
  },
  {
    def: [MoimURL.MoimLogin],
    exact: true,
    component: MoimLogin,
  },

  {
    def: [MoimURL.RedirectPlugin],
    exact: true,
    component: PluginRedirectDetector,
  },
  {
    def: MoimURL.Test,
    exact: true,
    component: TestBed,
  },
  {
    def: MoimURL.Sharing,
    exact: true,
    component: SharingRedirectDetector,
  },

  // moim app home은 맨 밑에 위치해야됨
  {
    def: MoimURL.MoimAppHome,
    component: Home,
    exact: true,
  },
];
