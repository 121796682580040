import * as React from "react";
import { Fade } from "@material-ui/core";
// actions
import { getProfileBlocks as getProfileBlocksAction } from "app/actions/user";
import {
  ActionCreators as ProfileActionCreators,
  getProfile,
} from "app/actions/profile";
// hooks
import { userSelector } from "app/selectors/user";
import { useActions, useStoreState } from "app/store";
import { useCancelTokenWithCancelHandler } from "common/hooks/useCancelToken";
// components
import ProfileDialogComponent from "./components/inner";
import ResponsiveMenu from "common/components/responsiveMenu";
import { PopoverStyle, BottomSheetHandleStyle } from "./styled";

const DEFAULT_MIN_HEIGHT = 200;

const ProfileDialog: React.FC = () => {
  const { cancelTokenSource, handleCancel } = useCancelTokenWithCancelHandler();
  const refContent = React.useRef<HTMLDivElement>(null);
  const [minHeight, setMinHeight] = React.useState(DEFAULT_MIN_HEIGHT);

  const { isOpen, anchorElement, targetUserId, user } = useStoreState(
    state => ({
      ...state.profileDialog,
      user: userSelector(state, state.profileDialog.targetUserId),
    }),
  );

  const { dispatchGetProfile, getProfileBlocks, close } = useActions({
    dispatchGetProfile: getProfile,
    getProfileBlocks: getProfileBlocksAction,
    close: ProfileActionCreators.closeProfileDialog,
  });

  const handleChangeBodyResize = React.useCallback(() => {
    setMinHeight(refContent?.current?.clientHeight ?? DEFAULT_MIN_HEIGHT);
  }, []);

  const handleClose = React.useCallback(() => {
    close();
  }, []);

  React.useEffect(() => {
    if (isOpen && targetUserId) {
      dispatchGetProfile({
        userId: targetUserId,
        cancelToken: cancelTokenSource.current.token,
      });
      getProfileBlocks(targetUserId, "preview");

      return () => {
        handleCancel();
      };
    }
  }, [isOpen, targetUserId]);

  if (!user || user.is_deactivated) {
    return null;
  }

  return (
    <ResponsiveMenu
      open={isOpen}
      anchorElement={anchorElement?.current}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      minHeight={minHeight}
      transitionComponent={Fade}
      paperOverrideStyle={PopoverStyle}
      bottomSheetHandleStyle={BottomSheetHandleStyle}
      marginThreshold={24}
      onCloseRequest={handleClose}
    >
      <ProfileDialogComponent
        ref={refContent}
        userId={targetUserId}
        userData={user}
        onCloseRequest={handleClose}
        onChangeBodyResize={handleChangeBodyResize}
      />
    </ResponsiveMenu>
  );
};

export default ProfileDialog;
