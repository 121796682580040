import { ActionCreators } from "./actions";
import { useActions, useStoreState } from "app/store";
import MoimAPI from "common/api";
import ShareBase from "common/components/share";
import useIsMobile from "common/hooks/useIsMobile";
import React, { useCallback } from "react";
import { useEffectOnce } from "react-use";

// NOTE: ref. https://firebase.google.com/docs/dynamic-links/create-manually?hl=ko
const DYNAMIC_LINK_QUERY_WHITE_LIST = [
  "apn",
  "amv",
  "ibi",
  "ius",
  "ipfl",
  "ipbi",
  "isi",
  "imv",
  "efr",
  "ofl",
  "st",
  "sd",
  "si",
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "at",
  "ct",
  "mt",
  "pt",
  "d",
];

interface IMakeDynamicLinkFriendlyUrlOptions {
  skipTransform?: boolean;
  useGivenShareUrlAsBase?: boolean;
}

export function useMakeDynamicLinkFriendlyUrl(
  shareUrl?: string,
  options: IMakeDynamicLinkFriendlyUrlOptions = {},
) {
  const appConfig = useStoreState((store) => store.app.appConfig);

  return React.useMemo(() => {
    if (shareUrl && !options.skipTransform && appConfig?.dynamicLinkUrl) {
      const url = new URL(
        options.useGivenShareUrlAsBase ? shareUrl : appConfig.dynamicLinkUrl,
      );
      url.searchParams.append("link", shareUrl);
      url.searchParams.append("afl", shareUrl);
      url.searchParams.append("ifl", shareUrl);

      Object.entries(appConfig).forEach(([key, value]) => {
        if (DYNAMIC_LINK_QUERY_WHITE_LIST.includes(key)) {
          url.searchParams.append(key, value);
        }
      });

      return url.toString();
    }
    return shareUrl;
  }, [appConfig, options, shareUrl]);
}

export function useMakeShortUrl(url?: string) {
  const [shortUrl, setShortUrl] = React.useState<string | undefined>(url);

  useEffectOnce(() => {
    if (url) {
      MoimAPI.group.makeShortUrl(url).then((res) => {
        setShortUrl(res.data.shortUrl);
      });
    }
  });

  return shortUrl;
}

export function useOpenSNSShareDialog(
  shareUrl?: string,
  makeDynamicLinkFriendlyOption?: IMakeDynamicLinkFriendlyUrlOptions,
) {
  const { open } = useActions({ open: ActionCreators.open });
  const reformedUrl = useMakeDynamicLinkFriendlyUrl(
    shareUrl,
    makeDynamicLinkFriendlyOption,
  );

  return useCallback(() => {
    if (reformedUrl) {
      open({ shareUrl: reformedUrl });
    }
  }, [open, reformedUrl]);
}
export function useCloseSNSShareDialog() {
  const { close } = useActions({ close: ActionCreators.close });

  return useCallback(() => {
    close();
  }, [close]);
}

export function Share({
  shareUrl,
  children,
  onClick,
  makeDynamicLinkFriendlyOption,
}: {
  shareUrl?: string;
  makeDynamicLinkFriendlyOption?: IMakeDynamicLinkFriendlyUrlOptions;
  onClick?: React.MouseEventHandler<HTMLElement>;
  children: (
    handler?: (e?: React.MouseEvent<HTMLElement>) => void,
  ) => React.ReactNode;
}) {
  const isMobile = useIsMobile();
  const reformedUrl = useMakeDynamicLinkFriendlyUrl(
    shareUrl,
    makeDynamicLinkFriendlyOption,
  );
  const openShareDialog = useOpenSNSShareDialog(
    shareUrl,
    makeDynamicLinkFriendlyOption,
  );

  const handler: React.MouseEventHandler<HTMLElement> = React.useCallback(
    (e) => {
      onClick?.(e);
      openShareDialog();
    },
    [openShareDialog, onClick],
  );
  const inner = React.useMemo(() => {
    if (!reformedUrl) {
      return children();
    }

    if (isMobile) {
      return <ShareBase displayText={children()} copyValue={reformedUrl} />;
    }

    return children(handler);
  }, [reformedUrl, isMobile, children, handler]);

  return <>{inner}</>;
}

/**
 * hook version of Common Share Module
 * @description opens SNSShareDialog on desktop, and opens native share module on mobile
 */
export function useShareHandler({
  shareData,
  afterShare,
  makeDynamicLinkFriendlyOption,
}: {
  shareData?: ShareData;
  makeDynamicLinkFriendlyOption?: IMakeDynamicLinkFriendlyUrlOptions;
  afterShare?: () => void;
  successMessageKey?: string;
  failMessageKey?: string;
}) {
  const isMobile = useIsMobile();
  const dynamicLinkFriendlyUrl = useMakeDynamicLinkFriendlyUrl(
    shareData?.url,
    makeDynamicLinkFriendlyOption,
  );
  const reformedUrl = useMakeShortUrl(dynamicLinkFriendlyUrl);
  const openShareDialog = useOpenSNSShareDialog(
    shareData?.url,
    makeDynamicLinkFriendlyOption,
  );

  const canShareAPI = React.useMemo(
    () => Boolean((window.navigator as any).share),
    [],
  );

  const handleShareClick: React.MouseEventHandler<HTMLSpanElement> =
    React.useCallback(async () => {
      if (isMobile && canShareAPI) {
        const shareText = shareData?.text
          ? `${shareData.text}\n${reformedUrl}`
          : reformedUrl;
        try {
          await (window.navigator as any).share({
            text: shareText,
          });
        } finally {
          afterShare?.();
        }
      } else {
        openShareDialog();
      }
    }, [
      isMobile,
      canShareAPI,
      shareData?.text,
      reformedUrl,
      afterShare,
      openShareDialog,
    ]);

  return handleShareClick;
}
