import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useEffectOnce } from "react-use";
import MoimAPI from "common/api";
import ShavedText from "common/components/shavedText";
import Share from "common/components/share";
import { Wrapper, Input, ShareUrl, CopyButton } from "./styled";
import { DefaultLoader } from "common/components/loading";

import { useStoreState } from "app/store";
import { AnalyticsClass } from "common/helpers/analytics/analytics";

export default function CopyShareUrlInput() {
  const { originShareUrl } = useStoreState(state => ({
    originShareUrl: state.SNSShareDialog.shareUrl,
  }));
  const [isLoading, setLoadStatus] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState(originShareUrl);

  const handleClickCopy = React.useCallback(() => {
    () => {
      if (shareUrl) {
        AnalyticsClass.getInstance().shareUrl({
          destination: "copy",
          url: shareUrl,
        });
      }
    };
  }, [shareUrl]);

  useEffectOnce(() => {
    if (originShareUrl && !isLoading) {
      setLoadStatus(true);
      MoimAPI.group
        .makeShortUrl(originShareUrl)
        .then(res => {
          setShareUrl(res.data.shortUrl);
        })
        .finally(() => {
          setLoadStatus(false);
        });
    }
  });

  if (!shareUrl) {
    return null;
  }

  return (
    <Wrapper>
      <Input>
        {isLoading ? (
          <DefaultLoader />
        ) : (
          <>
            <ShareUrl>
              <ShavedText line={1} value={shareUrl} />
            </ShareUrl>
            <Share
              displayText={
                <CopyButton onClick={handleClickCopy}>
                  <FormattedMessage id="copy_button" />
                </CopyButton>
              }
              copyValue={shareUrl}
            />
          </>
        )}
      </Input>
    </Wrapper>
  );
}
