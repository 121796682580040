import { Svg24SectionfolderG, Svg24SectionfolderGHover } from "@moim/icons";
import styled from "styled-components";



export const CollapseIcon = styled(Svg24SectionfolderG).attrs({
  size: "s",
  touch: 24,
})``;

export const CollapseHoverIcon = styled(Svg24SectionfolderGHover).attrs({
  size: "s",
  touch: 24,
})``;
