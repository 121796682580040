import * as React from "react";
import styled from "styled-components";

import {
  ProductItemCellAddCartButton,
  useAddCartButtonVisible,
} from "app/modules/commerce/components/productShow/layout/productSummary/components/right/purchaseGloveBox/components/buttons/addCart";
import { useOpenPurchasePreparationDialogWithAddCart } from "common/components/dialogs/purchasePreparationDialog/hooks/useOpen";
import { px2rem } from "common/helpers/rem";

const Wrapper = styled.div`
  width: 100%;
  padding: ${px2rem(4)} 0;
`;

interface IProps {
  product: Moim.Commerce.IProduct;
  block: Moim.Component.ProductItem.ICartButton;
  className?: string;
  onSelect?(): void;
}

const AddCartButton = ({ className, product, onSelect }: IProps) => {
  const openPurchasePreparationDialog = useOpenPurchasePreparationDialogWithAddCart(
    product.id,
  );
  const productType = product.type;
  const productStatus = product.status;
  const stockCount = product.stockCount;

  const addCartButtonVisible = useAddCartButtonVisible();

  const handleClickAddToCard = React.useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      openPurchasePreparationDialog({});
      onSelect?.();
    },
    [onSelect, openPurchasePreparationDialog],
  );
  if (!addCartButtonVisible) {
    return null;
  }
  return (
    <Wrapper key="button_buy_now" className={className}>
      <ProductItemCellAddCartButton
        key="button_add_to_cart"
        className={className}
        buttonSize="s"
        productType={productType}
        backorderAllowed={product?.backOrderAllowed}
        productStatus={productStatus}
        productStockCount={stockCount}
        onClick={handleClickAddToCard}
      />
    </Wrapper>
  );
};

export default React.memo(AddCartButton);
