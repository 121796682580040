import * as React from "react";
import * as qs from "query-string";
import { IntersectionOptions, InView } from "react-intersection-observer";
import { useActions, useStoreState } from "app/store";
import useRedirect from "common/hooks/useRedirect";
import useIsMobile from "common/hooks/useIsMobile";
import { activityLogAction } from "app/actions/activityLog";
import LazyBlurHashImage from "common/components/lazyBlurHashImage";
import CarouselVideo from "./carouselVideo";
import { LinkWrapper } from "./styled";
import { useActivityLogInjectorContext } from "app/common/helpers/activityLogPayloadInjectorContext/index";

interface IProps {
  media: Moim.Blockit.ICarouselImage;
  width: number;
  height: number;
}

export const WithCarouselLink = ({
  to,
  children,
  className,
}: React.PropsWithChildren<{
  to?: string;
  className?: string;
}>) => {
  const { isLoggedIn } = useStoreState(state => ({
    isLoggedIn: Boolean(state.app.currentUserId),
  }));
  const redirect = useRedirect();
  const redirectUrl = React.useMemo(() => {
    try {
      if (to) {
        const nl = new URL(to);
        if (nl.hostname === location.hostname) {
          const nlQuery = qs.parse(nl.search, {
            arrayFormat: "bracket",
          });

          const currentLocationQuery = qs.parse(location.search, {
            arrayFormat: "bracket",
          });

          const nextLocationQuery = qs.parse(nl.search, {
            arrayFormat: "bracket",
          });

          nl.search = qs.stringify({
            ...nlQuery,
            ...currentLocationQuery,
            ...nextLocationQuery,
          });
        }

        return nl.toString();
      }
    } catch {
      return;
    }
  }, [to]);

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = React.useCallback(
    e => {
      e.preventDefault();
      if (redirectUrl) {
        redirect(redirectUrl, undefined, isLoggedIn);
      }
    },
    [redirect, redirectUrl, isLoggedIn],
  );

  if (!to) {
    return <>{children}</>;
  }

  return (
    <LinkWrapper
      className={className}
      href={redirectUrl ?? "#"}
      onClick={handleClick}
    >
      {children}
    </LinkWrapper>
  );
};

const CarouselImageWithAnalytics = ({
  measurePayload,
  children,
}: React.PropsWithChildren<{
  measurePayload: Record<string, any>;
}>) => {
  const isMobile = useIsMobile();
  const activityLogInjector = useActivityLogInjectorContext();
  const { action } = useActions({
    action: activityLogAction,
  });

  const payload = React.useMemo(
    () => ({
      category: "carousel",
      platform: (isMobile ? "webMobile" : "web") as "web" | "webMobile",
      label1: measurePayload.fileId,
      label2: measurePayload.sourceUrl,
      label3: measurePayload.href,
      labelKey: {
        label1: "fileId",
        label2: "src",
        label3: "href",
      },
      referralUrl: activityLogInjector?.referralUrl ?? measurePayload.location,
    }),
    [measurePayload, isMobile, activityLogInjector],
  );

  const handleClick: React.MouseEventHandler<any> = React.useCallback(() => {
    action({
      event: "click",
      ...payload,
    });
  }, [payload]);

  const handleInViewChange: IntersectionOptions["onChange"] = React.useCallback(
    inView => {
      if (inView) {
        action({
          event: "impression",
          ...payload,
        });
      }
    },
    [payload],
  );

  return (
    <InView
      threshold={0.01}
      onClickCapture={handleClick}
      onChange={handleInViewChange}
    >
      {children}
    </InView>
  );
};

const CarouselItemElement: React.FC<IProps> = ({ media, width, height }) => {
  const isVideo = React.useMemo(() => media.mimetype?.includes("video"), [
    media.mimetype,
  ]);

  return (
    <WithCarouselLink key={media.src ?? media.imageSrc} to={media.href}>
      {isVideo ? (
        <CarouselVideo src={media.src} />
      ) : (
        <CarouselImageWithAnalytics
          measurePayload={{
            location: location.pathname,
            sourceUrl: media.src ?? media.imageSrc,
            href: media.href,
            fileId: (media.src ?? media.imageSrc).split("/").reverse()[1],
          }}
        >
          <LazyBlurHashImage
            src={media.src ?? media.imageSrc}
            alt={(media.src ?? media.imageSrc).split("/").pop() ?? ""}
            blurHash={media.blurHash ?? media.blur_hash}
            fallBackSrc={media.fallbackSrc ?? media.src ?? media.imageSrc}
            width={width}
            height={height}
          />
        </CarouselImageWithAnalytics>
      )}
    </WithCarouselLink>
  );
};

export default React.memo(CarouselItemElement);
