import { BG_LEVEL_BACKGROUND_CLASS_NAME } from "common/components/designSystem/BGLevel";
import { GhostButton } from "common/components/designSystem/buttons";
import { useScrollStyle } from "common/components/designSystem/styles";
import { MEDIA_QUERY } from "common/constants/responsive";
import { px2rem } from "common/helpers/rem";
import styled from "styled-components";

export const Wrapper = styled.div`
  flex: 1;
  min-width: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${useScrollStyle}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomSticky = styled.div.attrs({
  className: BG_LEVEL_BACKGROUND_CLASS_NAME,
})`
  position: sticky;
  bottom: 0;
  padding: 0 ${px2rem(40)} ${px2rem(40)};

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    padding: 0 ${px2rem(16)} ${px2rem(16)};
  }
`;

export const Button = styled(GhostButton).attrs({
  size: "l",
  isFullWidth: true,
})`
  margin: ${px2rem(16)};

  width: calc(100% - ${px2rem(32)});
`;
