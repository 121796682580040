import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import { useResizeDetector } from "react-resize-detector";
import useRedirect from "common/hooks/useRedirect";
import { MoimURL } from "common/helpers/url";
import DesktopSearchInput from "../../components/inputBar/desktop";

interface IProps {
  blockProperties?: Partial<Moim.Layout.Navigation.SearchElementType>;
}

const DesktopSearch: React.FC<IProps> = ({ blockProperties }) => {
  const { params } = useRouteMatch<Moim.IMatchParams>();
  const redirect = useRedirect();
  const [renderAsIcon, setResponsiveStatus] = React.useState(false);
  const [value, setValue] = React.useState(params.query ?? "");

  const handleEnter = React.useCallback(() => {
    if (value) {
      if (params.tab) {
        redirect(
          new MoimURL.SearchWithTab({
            query: value,
            tab: params.tab,
          }).toString(),
        );
      } else {
        redirect(new MoimURL.Search({ query: value }).toString());
      }
    }
  }, [params.tab, redirect, value]);

  const handleResize = React.useCallback((width: number) => {
    // NOTE: 140px is the width of the search input
    setResponsiveStatus(width < 140);
  }, []);

  const { ref } = useResizeDetector({
    handleWidth: true,
    refreshMode: "debounce",
    onResize: handleResize,
  });

  return (
    <div ref={ref}>
      <DesktopSearchInput
        value={value}
        renderAsIcon={renderAsIcon}
        blockProperties={blockProperties}
        onChange={setValue}
        onEnter={handleEnter}
      />
    </div>
  );
};

export default DesktopSearch;
