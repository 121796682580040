import React from "react";
// hook
import useRedirect from "common/hooks/useRedirect";
import useSideNavigationPanel from "common/hooks/useSideNavigationPanel";
// components
import ProfileDialogBody from "./components/body";
import ProfileDialogHeader from "./components/header";
import { Wrapper } from "./styledComponent";

import { MoimURL } from "common/helpers/url";
import ProfileDialogBlockedUserChecker from "../blockedUserChecker";

interface IProps {
  userId: Moim.Id;
  userData: Moim.User.IUser;

  onCloseRequest(): void;
  onChangeBodyResize(): void;
}

const ProfileDialogComponent = React.forwardRef<HTMLDivElement, IProps>(
  ({ userId, userData, onCloseRequest, onChangeBodyResize }, ref) => {
    const {
      isExpanded: sideNavigationExpanded,
      collapseSideNavigation,
    } = useSideNavigationPanel();
    const redirect = useRedirect();

    const openProfileSecondaryPanel = React.useCallback(() => {
      redirect(new MoimURL.ProfileShare({ userId }).toString());
    }, [redirect, userId]);

    const handleProfileClick = React.useCallback(() => {
      openProfileSecondaryPanel();
      onCloseRequest();
      if (sideNavigationExpanded) {
        collapseSideNavigation();
      }
    }, [
      onCloseRequest,
      collapseSideNavigation,
      openProfileSecondaryPanel,
      sideNavigationExpanded,
    ]);

    if (!userData) return null;

    return (
      <Wrapper ref={ref}>
        <ProfileDialogHeader
          userData={userData}
          onCloseRequest={onCloseRequest}
          onProfileClick={handleProfileClick}
        />
        <ProfileDialogBlockedUserChecker userId={userId}>
          <ProfileDialogBody
            userData={userData}
            onProfileClick={handleProfileClick}
            onChangeBodyResize={onChangeBodyResize}
            onCloseRequest={onCloseRequest}
          />
        </ProfileDialogBlockedUserChecker>
      </Wrapper>
    );
  },
);

export default ProfileDialogComponent;
