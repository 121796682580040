import * as React from "react";

import { FormattedMessage } from "react-intl";
import AppBar from "common/components/appBar";
import { WithoutMinHeightResponsiveDialog } from "common/components/basicResponsiveDialog";
import {
  CloseButton,
  CloseButtonWrapper,
} from "common/components/basicResponsiveDialog/styled";
import Inner from "./";
import { Wrapper, AppBarWrapper } from "./styled";

import { useStoreState } from "app/store";
import { useCloseChannelNotificationSettingDialog } from "./hooks";
import { useOpenStateWithHashRoute } from "common/hooks/useOpenState";

const HASH_KEY = "channelNotificationSetting";

export default function ChannelNotificationSettingDialog() {
  const closeAction = useCloseChannelNotificationSettingDialog();
  const { isOpen, type } = useStoreState(state => ({
    isOpen: state.channelNotificationSettingDialog.open,
    type: state.channelNotificationSettingDialog.type,
  }));
  const { open, close } = useOpenStateWithHashRoute(HASH_KEY, {
    onHashDismiss: closeAction,
  });

  const handleClose = React.useCallback(() => {
    closeAction();
    close();
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      open();
    }
  }, [isOpen]);

  return (
    <WithoutMinHeightResponsiveDialog open={isOpen} onClose={handleClose}>
      <Wrapper>
        <AppBarWrapper>
          <AppBar
            titleAlignment="Center"
            leftButton={
              <CloseButtonWrapper>
                <CloseButton onClick={handleClose} />
              </CloseButtonWrapper>
            }
            titleElement={
              type === "dm" ? (
                <FormattedMessage id="channel_notification_settings/dm_page_title" />
              ) : (
                <FormattedMessage id="channel_notification_settings/page_title" />
              )
            }
          />
        </AppBarWrapper>
        <Inner />
      </Wrapper>
    </WithoutMinHeightResponsiveDialog>
  );
}
