import * as React from "react";
import BlockitListLayout from "common/components/blockitListLayout";
import {
  DimmedLeftArrow,
  DimmedRightArrow,
} from "common/components/horizontalScroll/arrows";
import NavItem from "../quickLInkNavigation/components/item";
import { Wrapper } from "./styled";
import { withPlacement } from "../../hoc/withPlacement";
import InViewTrigger from "../../components/inViewTrigger";
import MoimAPI from "common/api";
import { MoimURL } from "common/helpers/url";

const BrandListPreview: React.FC<Moim.Blockit.V2.Commerce.IBrandListPreviewBlock> = ({
  listElement,
  brandIds,
  itemStyle,
}) => {
  const [brands, setBrands] = React.useState<Moim.Commerce.IBrand[]>();
  const handleOnView = React.useCallback(async () => {
    if (brandIds?.length) {
      const result = await MoimAPI.commerce.batchGetBrands(brandIds);
      setBrands(result.data);
    }
  }, [brandIds]);

  React.useEffect(() => {
    if (brandIds?.length) {
      handleOnView();
    }
  }, [brandIds]);

  return (
    <Wrapper>
      <InViewTrigger onVisible={handleOnView} />
      <BlockitListLayout
        element={listElement}
        leftArrow={DimmedLeftArrow}
        rightArrow={DimmedRightArrow}
      >
        {brands?.map(brand => (
          <NavItem
            key={brand.id}
            title={brand.name}
            description={brand.description}
            href={new MoimURL.CommerceBrandShow({ id: brand.id }).toString()}
            src={brand.logoImageUrl}
            itemStyle={itemStyle}
          />
        )) ?? []}
      </BlockitListLayout>
    </Wrapper>
  );
};

export default withPlacement(React.memo(BrandListPreview));
