import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
// components
import AppBar from "common/components/appBar";
import { CloseButton } from "common/components/responsiveDialog/styled";
import { CloseButtonWrapper, AppBarWrapper, Container, Dialog } from "./styled";

const DIALOG_MAX_HEIGHT = 678;
export const CompactLayout = ({
  children,
  open,
  onClose,
}: React.PropsWithChildren<{ open: boolean; onClose?(): void }>) => {
  const [height, setHeight] = React.useState<number>();
  const handleResize = React.useCallback((_, newHeight: number) => {
    setHeight(state => {
      if (!state && newHeight >= DIALOG_MAX_HEIGHT) {
        return DIALOG_MAX_HEIGHT;
      }

      return state;
    });
  }, []);

  const { ref: refContainer } = useResizeDetector({
    handleHeight: true,
    onResize: handleResize,
  });

  return (
    <Dialog open={open} height={height}>
      <Container ref={refContainer}>
        <AppBarWrapper>
          <AppBar
            leftButton={
              <CloseButtonWrapper>
                <CloseButton onClick={onClose} />
              </CloseButtonWrapper>
            }
            titleElement=""
          />
        </AppBarWrapper>

        {children}
      </Container>
    </Dialog>
  );
};
