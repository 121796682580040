import {
  AuthorWrapper,
  Engage,
  EngageProfileWithPositionChip,
  EngageWrapper,
  ReadTimeWrapper,
  Status,
  StatusWrapper,
  Wrapper,
} from "./styled";
import { VoteStatus } from "app/enums";
import { useStoreState } from "app/store";
import CreateDayOMeter from "common/components/createDayOMeter";
import { SmallComment } from "common/components/engage/comment";
import { SmallLike } from "common/components/engage/like";
import { SmallUpDown } from "common/components/engage/upDown";
import ViewCount from "common/components/engage/viewCount";
import ShavedText from "common/components/shavedText/v2";
import GroupAndChannelName from "common/components/thread/templates/common/channelName";
import UserProfileImage from "common/components/userProfileImage";
import { useCurrentUserLocale } from "common/hooks/localization/useCurrentUserLocale";
import useGroupTexts from "common/hooks/useGroupTexts";
import { useIntlShort } from "common/hooks/useIntlShort";
import * as React from "react";

export interface IProps {
  threadId: Moim.Id;
  isDraftThread?: boolean;
  upVoteCount: number;
  downVoteCount: number;
  commentCount: number;
  viewCount?: number;
  voteType?: Moim.Forum.PostReactionType;
  voteStatus?: Moim.Enums.VoteStatus;
  anonymousData?: Moim.Forum.IAnonymousData;
  disableAnonymousSuffix?: boolean;

  author: Moim.Id;
  createdAt: number;
  readTime?: number;
  editedAt?: number;

  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
  showReaction: boolean;
  showCommentCount: boolean;
  showAuthor: boolean;
  showAuthorAvatar: boolean;
  showAuthorPosition: boolean;
  showDate: boolean;
  showReadTime: boolean;
  showViewCount: boolean;
  showChannel?: boolean;
  showMoim?: boolean;

  channelId?: Moim.Id;
  groupId?: Moim.Id;
}

function Engagement({
  threadId,
  isDraftThread,
  voteType,
  voteStatus,
  upVoteCount,
  downVoteCount,
  commentCount,
  viewCount,
  author: authorId,
  createdAt,
  readTime,
  editedAt,
  textAlign,
  showReaction,
  showCommentCount,
  showAuthor,
  showAuthorAvatar,
  showAuthorPosition,
  showReadTime,
  showDate,
  showViewCount,
  showChannel,
  showMoim,
  anonymousData,
  disableAnonymousSuffix,
  channelId,
  groupId,
}: IProps) {
  const intl = useIntlShort();
  const author = useStoreState((state) => state.entities.users[authorId]);
  const anonymousTextKey = useGroupTexts("anonymous_member");
  const locale = useCurrentUserLocale();

  return (
    <Wrapper textAlign={textAlign}>
      {(showReaction || showCommentCount || showReadTime) && (
        <EngageWrapper>
          {Boolean(showReadTime) && (
            <ReadTimeWrapper>
              {intl("reading_time", { n: Math.round((readTime ?? 0) / 60) })}
            </ReadTimeWrapper>
          )}
          {showReaction && (
            <Engage>
              {voteType === "up" ? (
                <SmallLike
                  liked={voteStatus === VoteStatus.POSITIVE}
                  likeCount={upVoteCount}
                  threadId={threadId ?? ""}
                />
              ) : (
                <SmallUpDown
                  threadId={threadId ?? ""}
                  status={voteStatus ?? VoteStatus.NONE}
                  upCount={upVoteCount}
                  downCount={downVoteCount}
                  visibleNoneCountFallback={false}
                />
              )}
            </Engage>
          )}

          {showCommentCount && (
            <Engage>
              <SmallComment count={commentCount} />
            </Engage>
          )}

          {showViewCount && viewCount !== undefined ? (
            <Engage>
              <ViewCount count={viewCount} />
            </Engage>
          ) : null}
        </EngageWrapper>
      )}
      {(showChannel || showMoim) && (
        <GroupAndChannelName
          channelId={channelId}
          groupId={groupId}
          showChannel={showChannel}
          showMoim={showMoim}
          textAlign={textAlign}
        />
      )}
      {(showAuthor || showDate) && (
        <StatusWrapper>
          {showAuthor && (
            <AuthorWrapper key="author">
              {showAuthorAvatar && (
                <UserProfileImage
                  src={author?.avatar_url}
                  size="xs"
                  canOpenProfileDialog={false}
                />
              )}

              <EngageProfileWithPositionChip
                positions={author?.positions ?? []}
                hasPositionChip={showAuthorPosition}
              >
                <ShavedText line={1}>
                  {anonymousData
                    ? `${anonymousTextKey?.singular}${
                        !disableAnonymousSuffix
                          ? anonymousData.authorSuffix?.[locale]
                          : ""
                      }`
                    : author?.name ?? authorId}
                </ShavedText>
              </EngageProfileWithPositionChip>
            </AuthorWrapper>
          )}
          {showDate && (
            <Status key="createAt">
              <ShavedText line={1}>
                <CreateDayOMeter
                  key={isDraftThread ? "edited-at" : "create-at"}
                  givenDate={isDraftThread ? editedAt ?? createdAt : createdAt}
                  className="time"
                  useChange={false}
                  normalFormat={intl("datetime_format_short_tiny_date")}
                />
              </ShavedText>
            </Status>
          )}
        </StatusWrapper>
      )}
    </Wrapper>
  );
}

export default React.memo(Engagement);
