import * as React from "react";
import styled from "styled-components";

import {
  ProductItemCellBuyNowButton,
  useBuyNowButtonVisible,
} from "app/modules/commerce/components/productShow/layout/productSummary/components/right/purchaseGloveBox/components/buttons/buyNow";

import { useOpenPurchasePreparationDialogWithBuyNow } from "common/components/dialogs/purchasePreparationDialog/hooks/useOpen";
import { px2rem } from "common/helpers/rem";

const Wrapper = styled.div`
  width: 100%;
  padding: ${px2rem(4)} 0;
`;

interface IProps {
  className?: string;
  product: Moim.Commerce.IProduct;
  block: Moim.Component.ProductItem.IBuyNowButton;
  onSelect?(): void;
}

const BuyNowButton = ({ className, product, onSelect }: IProps) => {
  const openPurchasePreparationDialog = useOpenPurchasePreparationDialogWithBuyNow(
    product.id,
  );
  const productType = product.type;
  const productStatus = product.status;
  const stockCount = product.stockCount;

  const buyNowButtonVisible = useBuyNowButtonVisible(product.type);

  const handleClickBuyNow = React.useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      openPurchasePreparationDialog({});

      onSelect?.();
    },
    [onSelect, openPurchasePreparationDialog],
  );

  if (!buyNowButtonVisible) {
    return null;
  }
  return (
    <Wrapper key="button_buy_now" className={className}>
      <ProductItemCellBuyNowButton
        buttonSize="s"
        productType={productType}
        backorderAllowed={product?.backOrderAllowed}
        fundStatus={product.fundStatus}
        productStatus={productStatus}
        productStockCount={stockCount}
        onClick={handleClickBuyNow}
      />
    </Wrapper>
  );
};

export default React.memo(BuyNowButton);
