import * as React from "react";
import { FormattedMessage } from "react-intl";

import AppBar from "common/components/appBar";
import BasicResponsiveDialog from "common/components/basicResponsiveDialog";
import {
  CloseButton,
  AppBarWrapper,
  CloseButtonWrapper,
} from "common/components/basicResponsiveDialog/styled";
import Inner from ".";
import { Wrapper } from "./styled";

import { useStoreState } from "app/store";
import { useCloseSNSShareDialog } from "./utils";
import { useOpenStateWithHashRoute } from "common/hooks/useOpenState";

const HASH_KEY = "snsShare";

export default function SNSShareDialog() {
  const closeAction = useCloseSNSShareDialog();
  const { storeOpenStatus } = useStoreState(state => ({
    storeOpenStatus: state.SNSShareDialog.open,
  }));
  const { isOpen, open, close } = useOpenStateWithHashRoute(HASH_KEY, {
    onHashDismiss: closeAction,
  });

  const handleClose = React.useCallback(() => {
    closeAction();
    close();
  }, []);

  React.useEffect(() => {
    if (storeOpenStatus) {
      open();
    }
  }, [storeOpenStatus]);

  return (
    <BasicResponsiveDialog open={isOpen} onClose={handleClose}>
      <Wrapper>
        <AppBarWrapper>
          <AppBar
            titleAlignment="Center"
            leftButton={
              <CloseButtonWrapper>
                <CloseButton onClick={handleClose} />
              </CloseButtonWrapper>
            }
            titleElement={<FormattedMessage id="social_sharing/page_title" />}
          />
        </AppBarWrapper>
        <Inner />
      </Wrapper>
    </BasicResponsiveDialog>
  );
}
