import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useActions, useStoreState } from "app/store";
import useRedirect from "common/hooks/useRedirect";
import useBufferedUserPostingCountFetch from "common/components/blockitEditorBase/components/blockitRenderer/components/userProfilePreview/bufferedStatFetch";
import { MoimURL } from "common/helpers/url";
import { ActionCreators as ProfileActionCreators } from "app/actions/profile";
import {
  Wrapper,
  Title,
  Count,
  PostIconWrapper,
  PostIcon,
  RightArrow,
} from "./styled";
import { THREAD_TYPE } from "app/typings/thread";
import _ from "lodash";
import { buildTypeKey } from "app/reducers/userPageData/threads";

interface IProps {
  title?: string;
  pageTitle?: string;
  userId: Moim.Id;
  types: {
    type: THREAD_TYPE;
    subTypes?: THREAD_TYPE[];
  }[];
}

const UserPostCountStat: React.FC<IProps> = ({
  userId,
  types,
  title,
  pageTitle,
}) => {
  const redirect = useRedirect();
  const userWritingPostCountDispatch = useBufferedUserPostingCountFetch();
  const { dispatchCloseProfileDialog } = useActions({
    dispatchCloseProfileDialog: ProfileActionCreators.closeProfileDialog,
  });

  // type key 는 전체 조합
  // Count 는 전체 조합으로 조회 해도 무방
  // post|reply|nestedReply|review
  const typeKey = buildTypeKey(
    _.flatten(types.map(t => [t.type, ...(t.subTypes ?? [])])),
  );

  // post+reply_replyReply-reply+review
  const typeParams = types.map(t => {
    if (t.subTypes) {
      return `${t.type}_${t.subTypes.join("-")}`;
    }

    return t.type;
  });

  const postCount = useStoreState(
    state =>
      state.userPageData.threads[userId]?.typeThreadsCount[typeKey]?.total ?? 0,
  );

  const handleClick = React.useCallback(() => {
    const params = {
      types: typeParams.join("+"),
      tab: types[0].type,
    };

    if (pageTitle) {
      Object.assign(params, {
        pageTitle,
      });
    }

    const destination = new MoimURL.UserThreadList({ userId }).toString(
      undefined,
      params,
    );

    dispatchCloseProfileDialog();
    redirect(destination);
  }, [dispatchCloseProfileDialog, redirect, typeParams, types, userId]);

  React.useEffect(() => {
    userWritingPostCountDispatch(userId, typeKey.split("|") as THREAD_TYPE[]);
  }, [typeKey, userId, userWritingPostCountDispatch]);

  return (
    <Wrapper role="button" onClick={handleClick}>
      <PostIconWrapper>
        <PostIcon />
      </PostIconWrapper>
      <Title>
        {title ?? <FormattedMessage id="profile_show_created_content_title" />}
        <Count>{postCount}</Count>
      </Title>
      <RightArrow />
    </Wrapper>
  );
};

export default React.memo(UserPostCountStat);
