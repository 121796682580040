import * as React from "react";
import { isEqual } from "lodash";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";

import { DefaultLayout } from "app/modules/secondaryView/native/layout";
import useIsMobile from "common/hooks/useIsMobile";
import useCancelToken from "common/hooks/useCancelToken";
import { useActions, useStoreState } from "app/store";
import useRedirect from "common/hooks/useRedirect";
import { useEnableGuestCheckout } from "common/hooks/commerce/useCommerceConfig";
import useGroupTexts from "common/hooks/useGroupTexts";
import { paymentSelector } from "app/selectors/commerce";
import UnsignedChecker from "common/components/unsiginedChecker";
import { PermissionDeniedFallbackType } from "app/enums";
import {
  ActionCreators as CommerceActionCreators,
  getPayment as getPaymentAction,
} from "app/actions/commerce";
import { MoimURL } from "common/helpers/url";
import CancelPaymentDialog from "../paymentCancelDialog";
import PaymentDetailComponent from "./component";
import {
  AppBarTitleWrapper,
  AppBarStickyWrapperStyle,
  LeftButtonWrapper,
  BackIcon,
} from "./styled";
import { GUEST_PAYMENT_SESSION_TOKEN_KEY } from "common/constants/keys";

interface IProps {
  id: string;
}

export default function PaymentDetail({ id }: IProps) {
  const isMobile = useIsMobile();
  const cancelToken = useCancelToken();
  const redirect = useRedirect();
  const titleTexts = useGroupTexts("my_shopping_orders_details_title");
  const { key } = useLocation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const enableGuestCheckout = useEnableGuestCheckout();
  const paymentEntity: Moim.Commerce.IPayment | undefined = useStoreState(
    state => paymentSelector(state, id),
    isEqual,
  );

  const currentUserId = useStoreState(state => state.app.currentUserId);
  const {
    dispatchGetPayment,
    openPaymentCancelDialog,
    openPaymentConfirmDialog,
  } = useActions({
    dispatchGetPayment: getPaymentAction,
    openPaymentCancelDialog: CommerceActionCreators.openCancelPaymentDialog,
    openPaymentConfirmDialog:
      CommerceActionCreators.openCheckoutRedirectLoadingDialogAsConfirmation,
  });
  const guestUserPaymentToken = React.useMemo(() => {
    if (!enableGuestCheckout || currentUserId) {
      return undefined;
    }

    return sessionStorage.getItem(GUEST_PAYMENT_SESSION_TOKEN_KEY) ?? undefined;
  }, [currentUserId, enableGuestCheckout]);

  const handleBackButtonClick = React.useCallback(() => {
    redirect(new MoimURL.CommercePaymentsList().toString());
  }, [redirect]);

  const getPayment = React.useCallback(async () => {
    if (id) {
      try {
        setIsLoading(true);
        dispatchGetPayment(
          id,
          cancelToken.current.token,
          guestUserPaymentToken,
        );
      } finally {
        setIsLoading(false);
      }
    }
  }, [cancelToken, dispatchGetPayment, guestUserPaymentToken, id]);

  const handleAfterCancelSubmitAction = React.useCallback(() => {
    getPayment();
  }, [getPayment]);

  const handleClickConfirmPayment = React.useCallback(() => {
    if (paymentEntity) {
      openPaymentConfirmDialog({
        paymentId: paymentEntity.id,
        isFromCart: false,
      });
    }
  }, [openPaymentConfirmDialog, paymentEntity]);

  const handleClickPaymentCancel = React.useCallback(() => {
    if (paymentEntity) {
      openPaymentCancelDialog({
        type: "payment",
        targetPaymentId: paymentEntity.id,
        targetPurchaseItemIds: [],
        paymentMethodType: paymentEntity.payMethod,
        paymentStatus: paymentEntity.status,
        guestPaymentToken: guestUserPaymentToken,
      });
    }
  }, [openPaymentCancelDialog, guestUserPaymentToken, paymentEntity]);

  React.useEffect(() => {
    if (id && (currentUserId || enableGuestCheckout)) {
      window.scrollTo(0, 0);
      getPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, key]);

  return (
    <>
      <DefaultLayout
        appBar={{
          wrapperStickyStyle: AppBarStickyWrapperStyle,
          titleElement: (
            <AppBarTitleWrapper>
              {paymentEntity?.type === "candy" ? (
                <FormattedMessage id="my_shopping_purchase_details_payment_details_title" />
              ) : titleTexts ? (
                titleTexts.singular
              ) : (
                <FormattedMessage id="my_shopping/purchase_details/page_title" />
              )}
            </AppBarTitleWrapper>
          ),
          titleAlignment: "Center",
          leftButton: !isMobile && (
            <LeftButtonWrapper onClick={handleBackButtonClick}>
              <BackIcon />
            </LeftButtonWrapper>
          ),
          enableScrollParallax: true,
          alwaysShowAppBarTitle: true,
        }}
      >
        {enableGuestCheckout ? (
          <PaymentDetailComponent
            payment={paymentEntity}
            isLoading={isLoading}
            onClickConfirmPayment={handleClickConfirmPayment}
            onClickPaymentCancel={handleClickPaymentCancel}
          />
        ) : (
          <UnsignedChecker fallbackType={PermissionDeniedFallbackType.SCREEN}>
            <PaymentDetailComponent
              payment={paymentEntity}
              isLoading={isLoading}
              onClickConfirmPayment={handleClickConfirmPayment}
              onClickPaymentCancel={handleClickPaymentCancel}
            />
          </UnsignedChecker>
        )}
      </DefaultLayout>

      <CancelPaymentDialog
        onAfterCancelSubmitAction={handleAfterCancelSubmitAction}
      />
    </>
  );
}
