// Zeplin https://app.zeplin.io/project/5db7ff7b7ef2d22c5191d3cb/screen/5f5c3692145e177a62492dd2
import * as React from "react";
import { useActions, useStoreState } from "app/store";
import { doBlockAction as doBlockActionDispatch } from "app/actions/referenceBlock";
import {
  H1Regular,
  H1Bold,
  H2Regular,
  H2Bold,
  H3Regular,
  H3Bold,
  H4Regular,
  H4Bold,
  H5Regular,
  H5Bold,
  H6Regular,
  H6Bold,
  H7Regular,
  H7Bold,
  H8Regular,
  H8Bold,
  H9Regular,
  H9Bold,
  H10Regular,
  H10Bold,
  B1Regular,
  B1Bold,
  PB1Regular,
  PB1Bold,
  B2Regular,
  B2Bold,
  PB2Regular,
  PB2Bold,
  B3Regular,
  B3Bold,
  PB3Regular,
  PB3Bold,
  B4Regular,
  B4Bold,
  PB4Regular,
  PB4Bold,
  PlainText,
  CaptionRegular,
} from "./styled";
import { PluginPanelContext } from "app/modules/secondaryView/plugin/context";

import { parseCommonFormat } from "common/helpers/moimDown";
import { browserLocale } from "app/intl";
import { withPlacement } from "../../hoc/withPlacement";
import { WithCarouselLink } from "../carousel/item";

interface IProps extends Omit<Moim.Blockit.V2.ITextBlock, "content"> {
  content: React.ReactNode;
  className?: string;
}

const Texts: React.FC<IProps> = ({
  className,
  botId,
  content,
  color,
  align,
  subType_v2: subType,
  actionId,
  params,
  href,
  border,
  textSets,
  fontWeight,
  fontFamily,
  letterSpacing,
}) => {
  const weight = fontWeight;

  const isInPluginPanel = React.useContext(PluginPanelContext);
  const { defaultLocale } = useStoreState(storeState => ({
    defaultLocale: storeState.app.locale,
  }));
  const { doBlockAction } = useActions({
    doBlockAction: doBlockActionDispatch,
  });
  const locale = browserLocale(defaultLocale ?? undefined);
  const role = React.useMemo(() => (actionId ? "button" : undefined), [
    actionId,
  ]);
  const displayContent = React.useMemo(() => {
    if (typeof content === "string") {
      let newText = content;
      const parsedResult = parseCommonFormat(content);
      parsedResult.forEach(item => {
        let replaceData = item.fallback;

        switch (item.type) {
          case "text_sets": {
            const textSet = textSets?.[item.value];
            if (textSet) {
              replaceData = textSet[locale].singular;
            }
            break;
          }
        }

        newText = newText.replace(item.origin, replaceData);
      });

      return (
        <span>
          <WithCarouselLink to={href}>{newText}</WithCarouselLink>
        </span>
      );
    }

    return (
      <span>
        <WithCarouselLink to={href}>{content}</WithCarouselLink>
      </span>
    );
  }, [href, content, locale, textSets]);

  const handleClick = React.useCallback(
    e => {
      if (botId && actionId) {
        e.preventDefault();
        e.stopPropagation();
        doBlockAction(
          {
            botId,
            data: {
              actionId,
              params,
            },
          },
          isInPluginPanel,
        );
      }
    },
    [actionId, botId, doBlockAction, isInPluginPanel, params],
  );

  if (!subType) {
    return <PlainText>{displayContent}</PlainText>;
  }

  switch (subType) {
    case "h1Regular":
      return (
        <H1Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H1Regular>
      );
    case "h1Bold":
      return (
        <H1Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H1Bold>
      );
    case "h2Regular":
      return (
        <H2Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H2Regular>
      );
    case "h2Bold":
      return (
        <H2Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H2Bold>
      );
    case "h3Regular":
      return (
        <H3Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H3Regular>
      );
    case "h3Bold":
      return (
        <H3Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H3Bold>
      );
    case "h4Regular":
      return (
        <H4Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H4Regular>
      );
    case "h4Bold":
      return (
        <H4Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H4Bold>
      );
    case "h5Regular":
      return (
        <H5Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H5Regular>
      );
    case "h5Bold":
      return (
        <H5Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H5Bold>
      );
    case "h6Regular":
      return (
        <H6Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H6Regular>
      );
    case "h6Bold":
      return (
        <H6Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H6Bold>
      );
    case "h7Regular":
      return (
        <H7Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H7Regular>
      );
    case "h7Bold":
      return (
        <H7Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H7Bold>
      );
    case "h8Regular":
      return (
        <H8Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H8Regular>
      );
    case "h8Bold":
      return (
        <H8Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H8Bold>
      );
    case "h9Regular":
      return (
        <H9Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H9Regular>
      );
    case "h9Bold":
      return (
        <H9Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H9Bold>
      );
    case "h10Regular":
      return (
        <H10Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H10Regular>
      );
    case "h10Bold":
      return (
        <H10Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </H10Bold>
      );
    case "b1Regular":
      return (
        <B1Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </B1Regular>
      );
    case "b1Bold":
      return (
        <B1Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </B1Bold>
      );
    case "pB1Regular":
      return (
        <PB1Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </PB1Regular>
      );
    case "pB1Bold":
      return (
        <PB1Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </PB1Bold>
      );
    case "b2Regular":
      return (
        <B2Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </B2Regular>
      );
    case "b2Bold":
      return (
        <B2Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </B2Bold>
      );
    case "pB2Regular":
      return (
        <PB2Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </PB2Regular>
      );
    case "pB2Bold":
      return (
        <PB2Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </PB2Bold>
      );
    case "b3Regular":
      return (
        <B3Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </B3Regular>
      );
    case "b3Bold":
      return (
        <B3Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </B3Bold>
      );
    case "pB3Regular":
      return (
        <PB3Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </PB3Regular>
      );
    case "pB3Bold":
      return (
        <PB3Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </PB3Bold>
      );
    case "b4Regular":
      return (
        <B4Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </B4Regular>
      );
    case "b4Bold":
      return (
        <B4Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </B4Bold>
      );
    case "pB4Regular":
      return (
        <PB4Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </PB4Regular>
      );
    case "pB4Bold":
      return (
        <PB4Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </PB4Bold>
      );
    case "caption":
      return (
        <CaptionRegular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={handleClick}
        >
          {displayContent}
        </CaptionRegular>
      );
  }
};

export default withPlacement(Texts);
