import React from "react";

import BlockitRendererV2 from "common/components/blockitEditorBase/components/blockitRenderer.v2";
import BasicResponsiveDialog from "common/components/basicResponsiveDialog";
import { BottomSticky, Button, Wrapper, Content } from "./styled";

import { useStoreState } from "app/store";
import { useIntlShort } from "common/hooks/useIntlShort";
import useCurrentUser from "common/hooks/useCurrentUser";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { useCloseGlobalUserStatusFeedbackDialog } from "./hooks";
import { UserStatusTypes } from "app/enums";

const GlobalUserStatusFeedback: React.FC<{ onClose(): void }> = ({
  onClose,
}) => {
  const intl = useIntlShort();
  const currentUser = useCurrentUser();
  const currentGroup = useCurrentGroup();

  const blocks = React.useMemo(() => {
    switch (currentUser?.status) {
      case UserStatusTypes.WAITING_FOR_APPROVAL:
        return currentGroup?.sign_up_config_v2.waitingSignUpDialog;
      case UserStatusTypes.REJECTED:
        return currentGroup?.sign_up_config_v2.rejectSignUpDialog;
    }
  }, [currentUser?.status, currentGroup?.sign_up_config_v2]);

  const buttonText = React.useMemo(() => {
    switch (currentUser?.status) {
      case UserStatusTypes.WAITING_FOR_APPROVAL:
        return intl("button_logout_from_waiting_for_approval_dialog");
      case UserStatusTypes.REJECTED:
        return intl("button_logout_from_signup_rejected_dialog");
    }
  }, [currentUser?.status]);

  return (
    <Wrapper>
      <Content>
        {blocks?.map((block, index) => {
          return (
            <BlockitRendererV2 key={`${block.type}_${index}`} block={block} />
          );
        })}
      </Content>
      <BottomSticky>
        <Button onClick={onClose}>{buttonText}</Button>
      </BottomSticky>
    </Wrapper>
  );
};
const GlobalUserStatusFeedbackDialog: React.FC = () => {
  const { open, joinGroupDialogOpen } = useStoreState(state => {
    return {
      open: state.globalUserStatusFeedbackDialog.open,
      joinGroupDialogOpen: state.joinGroupDialog.open,
    };
  });
  const onClose = useCloseGlobalUserStatusFeedbackDialog();

  // NOTE:  join process 중에 백엔드에서 필수 값이 채워지면 status가 바뀌기 떄문에 joinGroupDialog가 열려있으면 해당 다이얼로그를 노출하지 않도록 변경
  //        => 추후 user status 를 바꾸는 로직이 백엔드가 아닌 클라에서 api로 호출하는식으로 변경되면 제거
  if (joinGroupDialogOpen) {
    return null;
  }
  return (
    <BasicResponsiveDialog open={open} onClose={onClose}>
      <GlobalUserStatusFeedback onClose={onClose} />
    </BasicResponsiveDialog>
  );
};

export default GlobalUserStatusFeedbackDialog;
