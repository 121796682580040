import {
  Svg24PolygonSolid,
  Svg24Usdc,
  Svg24Link,
  Svg24Usdt,
  Svg24Ape,
  Svg24Sand,
  Svg24Shib,
  Svg24Near,
  Svg24Avax,
  Svg24Mana,
  Svg18PolygonSolid,
  Svg18Usdc,
  Svg18Link,
  Svg18Usdt,
  Svg18Ape,
  Svg18Sand,
  Svg18Shib,
  Svg18Near,
  Svg18Avax,
  Svg18Mana,
  Svg24EthereumBlack,
  Svg18EthereumBlack,
} from "@moim/icons";
import styled from "styled-components";

export const PolygonIcon = styled(Svg24PolygonSolid).attrs(props => ({
  size: props.size ?? "s",
}))``;
export const EthereumIcon = styled(Svg24EthereumBlack).attrs(props => ({
  size: props.size ?? "s",
}))``;
export const LINKIcon = styled(Svg24Link).attrs(props => ({
  size: props.size ?? "s",
}))``;
export const USDTIcon = styled(Svg24Usdt).attrs(props => ({
  size: props.size ?? "s",
}))``;
export const USDCIcon = styled(Svg24Usdc).attrs(props => ({
  size: props.size ?? "s",
}))``;
export const APEIcon = styled(Svg24Ape).attrs(props => ({
  size: props.size ?? "s",
}))``;
export const SANDIcon = styled(Svg24Sand).attrs(props => ({
  size: props.size ?? "s",
}))``;
export const SHIBIcon = styled(Svg24Shib).attrs(props => ({
  size: props.size ?? "s",
}))``;
export const NEARIcon = styled(Svg24Near).attrs(props => ({
  size: props.size ?? "s",
}))``;
export const AVAXIcon = styled(Svg24Avax).attrs(props => ({
  size: props.size ?? "s",
}))``;
export const MANAIcon = styled(Svg24Mana).attrs(props => ({
  size: props.size ?? "s",
}))``;

export const PolygonIconXS = styled(Svg18PolygonSolid).attrs({ size: "xs" })``;
export const EthereumIconXS = styled(Svg18EthereumBlack).attrs({
  size: "xs",
})``;
export const LINKIconXS = styled(Svg18Link).attrs({ size: "xs" })``;
export const USDTIconXS = styled(Svg18Usdt).attrs({ size: "xs" })``;
export const USDCIconXS = styled(Svg18Usdc).attrs({ size: "xs" })``;
export const APEIconXS = styled(Svg18Ape).attrs({ size: "xs" })``;
export const SANDIconXS = styled(Svg18Sand).attrs({ size: "xs" })``;
export const SHIBIconXS = styled(Svg18Shib).attrs({ size: "xs" })``;
export const NEARIconXS = styled(Svg18Near).attrs({ size: "xs" })``;
export const AVAXIconXS = styled(Svg18Avax).attrs({ size: "xs" })``;
export const MANAIconXS = styled(Svg18Mana).attrs({ size: "xs" })``;
