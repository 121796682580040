import { Svg18Instargram, Svg18Slack, Svg18NaverBand, Svg18Reddit, Svg18MoreG } from "@moim/icons";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B3Regular } from "common/components/designSystem/typos";







export const Wrapper = styled.div`
  width: 100%;
  margin-top: ${px2rem(8)};
`;

export const Tip = styled(B3Regular)`
  width: 100%;
  height: ${px2rem(42)};
  color: ${props => props.theme.colorV2.colorSet.grey600};
  padding: 0 ${px2rem(16)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * + * {
    margin-left: ${px2rem(8)};
  }
`;

export const InstargramIcon = styled(Svg18Instargram).attrs({
  role: "button",
  size: "xs",
})``;
export const SlackIcon = styled(Svg18Slack).attrs({
  role: "button",
  size: "xs",
})``;
export const NaverBandIcon = styled(Svg18NaverBand).attrs({
  role: "button",
  size: "xs",
})``;
export const RedditIcon = styled(Svg18Reddit).attrs({
  role: "button",
  size: "xs",
})``;
export const MoreIcon = styled(Svg18MoreG).attrs(props => ({
  role: "button",
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey600,
}))``;
