import {
  Center,
  Date,
  EngageProfileWithPositionChip,
  IconWrapper,
  Left,
  MoreIcon,
  Right,
  UserInfoWrapper,
  UserName,
} from "./styled";
import { ItemIdTypes } from "app/enums";
import CreateDayOMeter from "common/components/createDayOMeter";
import ShavedTextV2 from "common/components/shavedText/v2";
import { useThreadItemContext } from "common/components/thread/commonThreadItem/context";
import UserProfileImage from "common/components/userProfileImage";
import { useCurrentUserLocale } from "common/hooks/localization/useCurrentUserLocale";
import { useIntlShort } from "common/hooks/useIntlShort";
import * as React from "react";

function UserInfo({
  disableAnonymousSuffix,
  author,
  positions,
  onUserInfoClick,
  onMenuClick,
  refMenuButton,
}: {
  disableAnonymousSuffix?: boolean;
  author?: Moim.User.IOriginalUserDatum;
  positions: Moim.Position.IPosition[];
  onUserInfoClick?: React.MouseEventHandler;
  onMenuClick?: React.MouseEventHandler;
  refMenuButton?: React.RefObject<HTMLDivElement>;
}) {
  const locale = useCurrentUserLocale();
  const {
    isDraftThread,
    createdAt,
    editedAt,
    showAuthor,
    showAuthorAvatar,
    showAuthorPosition,
    showDate,
    anonymousData,
    authorId,
  } = useThreadItemContext((state) => ({
    isDraftThread: state.item.id.startsWith(ItemIdTypes.DRAFT),
    createdAt: state.item.created_at,
    editedAt: state.item.edited_at,
    showAuthor: state.config.showAuthor,
    showAuthorAvatar: state.config.showAuthorAvatar,
    showAuthorPosition: state.config.showAuthorPosition,
    showDate: state.config.showDate,
    anonymousData: state.item.anonymous_data,
    authorId: state.item.author,
  }));

  const handleClick = React.useCallback(
    (callback?: React.MouseEventHandler) => (event: React.MouseEvent) => {
      event.stopPropagation();
      if (callback) {
        callback(event);
      }
    },
    [],
  );

  const intl = useIntlShort();
  const anonymousTextKey = intl("anonymous_member");

  const isVisible = showAuthorAvatar || showAuthor;

  if (!isVisible) {
    return null;
  }
  return (
    <UserInfoWrapper onClick={handleClick(onUserInfoClick)}>
      <Left>
        {showAuthorAvatar && (
          <UserProfileImage src={author?.avatar_url} size="m" />
        )}
      </Left>
      <Center>
        <EngageProfileWithPositionChip
          positions={positions ?? []}
          hasPositionChip={showAuthorPosition}
        >
          <UserName>
            <ShavedTextV2 line={1}>
              {anonymousData
                ? `${anonymousTextKey}${
                    !disableAnonymousSuffix
                      ? anonymousData.authorSuffix?.[
                          locale as keyof typeof anonymousData.authorSuffix
                        ]
                      : ""
                  }`
                : author?.name ?? authorId}
            </ShavedTextV2>
          </UserName>
        </EngageProfileWithPositionChip>

        {showDate && (
          <Date key="createAt">
            <ShavedTextV2 line={1}>
              <CreateDayOMeter
                key={isDraftThread ? "edited-at" : "create-at"}
                givenDate={isDraftThread ? editedAt ?? createdAt : createdAt}
                className="time"
                useChange={false}
                normalFormat={intl("datetime_format_short_tiny_date")}
              />
            </ShavedTextV2>
          </Date>
        )}
      </Center>
      <Right>
        <IconWrapper onClick={handleClick(onMenuClick)} ref={refMenuButton}>
          <MoreIcon />
        </IconWrapper>
      </Right>
    </UserInfoWrapper>
  );
}

export default React.memo(UserInfo);
